import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { Label } from '@components/Label';
import { Select, SelectItem } from '@components/Select';
import { Stack, TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const CURRENCY = [
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'USD',
    value: 'USD',
  },
];

export function CurrentAvailability() {
  const { control, getValues, watch } = useFormContext();
  const watchCapacityUoM = watch('storageCapacityUnitOfMeasure');

  return (
    <Stack spacing={16}>
      <Heading as='h4' size='xs'>
        Pricing
      </Heading>
      <InputGroup
        direction='column'
        css={{
          gap: '$space16',
          padding: '$space12 $space0',
        }}
      >
        <Label
          htmlFor='currency'
          css={{ fontSize: '$textmd', fontWeight: 'bold' }}
        >
          Currency *
        </Label>
        <Controller
          name='currency'
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <Select
                onValueChange={field.onChange}
                defaultValue={getValues(`currency`)}
                onBlur={field.onBlur}
                placeholder='Please select'
                css={{ width: '25%' }}
                isInvalid={Boolean(error)}
              >
                {CURRENCY.map((currency) => (
                  <SelectItem key={currency.label} value={currency.value}>
                    {`${currency.label}`}
                  </SelectItem>
                ))}
              </Select>
            );
          }}
        />
      </InputGroup>
      {watchCapacityUoM === 'Pallets' ? (
        <Controller
          name='palletStoragePricePerWeek'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={Boolean(error)}
              helperText={Boolean(error) && error?.message}
              variant='outlined'
              label='Storage (pallet), per pallet-week'
              placeholder='Storage (pallet), per pallet-week'
              sx={{
                width: '100%',
              }}
            />
          )}
        />
      ) : (
        <Controller
          name={watchCapacityUoM === 'Square Foot' ? 'squareFeetStoragePricePerWeek' : 'squareMetreStoragePricePerWeek'}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={Boolean(error)}
              helperText={Boolean(error) && error?.message}
              variant='outlined'
              label={`Storage (space), per ${watchCapacityUoM}-week`}
              placeholder={`Storage (space), per ${watchCapacityUoM}-week`}
              sx={{
                width: '100%',
              }}
            />
          )}
        />
      )}
      <Controller
        name='palletReceiptAndPutAwayPrice'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Receipt and putaway, per pallet'
            placeholder='Receipt and putaway, per pallet'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='palletPickingAndDespatchPrice'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Pallet picking and despatch, per pallet'
            placeholder='Pallet picking and despatch, per pallet'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='casePickingAndDespatchPrice'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Case picking and despatch, per case/parcel'
            placeholder='Case picking and despatch, per case/parcel'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='itemPickingAndDespatchPrice'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Item picking and despatch, per item'
            placeholder='Item picking and despatch, per item'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='containerHandlingPrice'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Container handling, per container'
            placeholder='Container handling, per container'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='demurragePricePerDay'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Demurrage, per consignment day'
            placeholder='Demurrage, per consignment day'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='palletReturnsPrice'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Returns, per pallet'
            placeholder='Returns, per pallet'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='itemReturnsPrice'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Returns, per item'
            placeholder='Returns, per item'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='caseReturnsPrice'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Returns, per case'
            placeholder='Returns, per case'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='auxiliaryServicePricePerManHour'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Auxiliary services (eg re-label, pallet rebuild, disposal), per main-hour'
            placeholder='Auxiliary services (eg re-label, pallet rebuild, disposal), per main-hour'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
      <Controller
        name='additionalSurchargesPercentage'
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            error={Boolean(error)}
            helperText={Boolean(error) && error?.message}
            variant='outlined'
            label='Surcharges (eg rush orders, out of hours, etc.), additional % to the respective base rate'
            placeholder='Surcharges (eg rush orders, out of hours, etc.), additional % to the respective base rate'
            sx={{
              width: '100%',
            }}
          />
        )}
      />
    </Stack>
  );
}
