import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { BoardList } from '@components/Board';

interface InvoicingEventListProps extends ComponentPropsWithoutRef<'div'> {
  id: 'invoice-list' | 'payment-list';
}

const InvoicingEventList = forwardRef<HTMLDivElement, InvoicingEventListProps>(
  ({ children, id }, forwardedRef) => {
    return (
      <BoardList id={id} ref={forwardedRef}>
        {children}
      </BoardList>
    );
  }
);

InvoicingEventList.displayName = 'InvoicingEventList';

type InvoiceEventListProps = ComponentPropsWithoutRef<'div'>;

export const InvoiceEventList = forwardRef<
  HTMLDivElement,
  InvoiceEventListProps
>(({ children }, forwardedRef) => {
  return (
    <InvoicingEventList id='invoice-list' ref={forwardedRef}>
      {children}
    </InvoicingEventList>
  );
});

InvoiceEventList.displayName = 'InvoiceEventList';

type PaymentEventListProps = ComponentPropsWithoutRef<'div'>;

export const PaymentEventList = forwardRef<
  HTMLDivElement,
  PaymentEventListProps
>(({ children }, forwardedRef) => {
  return (
    <InvoicingEventList id='payment-list' ref={forwardedRef}>
      {children}
    </InvoicingEventList>
  );
});

PaymentEventList.displayName = 'PaymentEventList';
