/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useMediaQuery } from 'usehooks-ts';
import dayjs from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
import { Heading } from '@components/Heading';
import { InputGroup } from '@components/InputGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import { Select } from '@headlessui/react';
// eslint-disable-next-line import/no-cycle
import { Match, Warehouse } from './WarehouseDetails';
import { SUPPORTED_CURRENCY_GROUPS } from '../../constants';
import { ProposalFormValues } from './ClientServiceRatesSideDrawer';

const validationSchema = Yup.object().shape({
  storagePricePerWeek: Yup.string().when(
    ['volumeBasedDiscount', 'storagePricePerWeekUnavailable'],
    ([volumeBasedDiscount, storagePricePerWeekUnavailable], $schema) =>
      volumeBasedDiscount === true || storagePricePerWeekUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  storagePricePerWeekUnavailable: Yup.boolean(),
  palletReceiptAndPutAwayPrice: Yup.string().when(
    ['volumeBasedDiscount', 'palletReceiptAndPutAwayPriceUnavailable'],
    ([volumeBasedDiscount, palletReceiptAndPutAwayPriceUnavailable], $schema) =>
      volumeBasedDiscount === true ||
      palletReceiptAndPutAwayPriceUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  palletReceiptAndPutAwayPriceUnavailable: Yup.boolean(),
  palletPickingAndDespatchPrice: Yup.string().when(
    ['volumeBasedDiscount', 'palletPickingAndDespatchPriceUnavailable'],
    (
      [volumeBasedDiscount, palletPickingAndDespatchPriceUnavailable],
      $schema
    ) =>
      volumeBasedDiscount === true ||
      palletPickingAndDespatchPriceUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  palletPickingAndDespatchPriceUnavailable: Yup.boolean(),
  casePickingAndDespatchPrice: Yup.string().when(
    ['volumeBasedDiscount', 'casePickingAndDespatchPriceUnavailable'],
    ([volumeBasedDiscount, casePickingAndDespatchPriceUnavailable], $schema) =>
      volumeBasedDiscount === true ||
      casePickingAndDespatchPriceUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  casePickingAndDespatchPriceUnavailable: Yup.boolean(),
  itemPickingAndDespatchPrice: Yup.string().when(
    ['volumeBasedDiscount', 'itemPickingAndDespatchPriceUnavailable'],
    ([volumeBasedDiscount, itemPickingAndDespatchPriceUnavailable], $schema) =>
      volumeBasedDiscount === true ||
      itemPickingAndDespatchPriceUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  itemPickingAndDespatchPriceUnavailable: Yup.boolean(),
  containerHandlingPrice: Yup.string().when(
    ['volumeBasedDiscount', 'containerHandlingPriceUnavailable'],
    ([volumeBasedDiscount, containerHandlingPriceUnavailable], $schema) =>
      volumeBasedDiscount === true || containerHandlingPriceUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  containerHandlingPriceUnavailable: Yup.boolean(),
  demurragePricePerDay: Yup.string().when(
    ['volumeBasedDiscount', 'demurragePricePerDayUnavailable'],
    ([volumeBasedDiscount, demurragePricePerDayUnavailable], $schema) =>
      volumeBasedDiscount === true || demurragePricePerDayUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  demurragePricePerDayUnavailable: Yup.boolean(),
  palletReturnsPrice: Yup.string().when(
    ['volumeBasedDiscount', 'palletReturnsPriceUnavailable'],
    ([volumeBasedDiscount, palletReturnsPriceUnavailable], $schema) =>
      volumeBasedDiscount === true || palletReturnsPriceUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  palletReturnsPriceUnavailable: Yup.boolean(),
  itemReturnsPrice: Yup.string().when(
    ['volumeBasedDiscount', 'itemReturnsPriceUnavailable'],
    ([volumeBasedDiscount, itemReturnsPriceUnavailable], $schema) =>
      volumeBasedDiscount === true || itemReturnsPriceUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  itemReturnsPriceUnavailable: Yup.boolean(),
  caseReturnsPrice: Yup.string().when(
    ['volumeBasedDiscount', 'caseReturnsPriceUnavailable'],
    ([volumeBasedDiscount, caseReturnsPriceUnavailable], $schema) =>
      volumeBasedDiscount === true || caseReturnsPriceUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  caseReturnsPriceUnavailable: Yup.boolean(),
  auxiliaryServicePricePerManHour: Yup.string().when(
    ['volumeBasedDiscount', 'auxiliaryServicePricePerManHourUnavailable'],
    (
      [volumeBasedDiscount, auxiliaryServicePricePerManHourUnavailable],
      $schema
    ) =>
      volumeBasedDiscount === true ||
      auxiliaryServicePricePerManHourUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  auxiliaryServicePricePerManHourUnavailable: Yup.boolean(),
  additionalSurchargesPercentage: Yup.string().when(
    ['volumeBasedDiscount', 'additionalSurchargesPercentageUnavailable'],
    (
      [volumeBasedDiscount, additionalSurchargesPercentageUnavailable],
      $schema
    ) =>
      volumeBasedDiscount === true ||
      additionalSurchargesPercentageUnavailable === true
        ? $schema.nullable()
        : $schema.required('This field is required')
  ),
  additionalSurchargesPercentageUnavailable: Yup.boolean(),
  volumeBasedDiscount: Yup.boolean(),
  monthlyTurnoverOne: Yup.string().when(
    'volumeBasedDiscount',
    ([volumeBasedDiscount], $schema) =>
      volumeBasedDiscount === true
        ? $schema
            .required('This field is required')
            .notOneOf(
              [Yup.ref('monthlyTurnoverTwo'), Yup.ref('monthlyTurnoverThree')],
              'This value must be unique'
            )
        : $schema
  ),
  monthlyDiscountOne: Yup.string().when(
    'volumeBasedDiscount',
    ([volumeBasedDiscount], $schema) =>
      volumeBasedDiscount === true
        ? $schema.required('This field is required')
        : $schema
  ),
  monthlyTurnoverTwo: Yup.string().when(
    'volumeBasedDiscount',
    ([volumeBasedDiscount], $schema) =>
      volumeBasedDiscount === true
        ? $schema
            .required('This field is required')
            .notOneOf(
              [Yup.ref('monthlyTurnoverOne'), Yup.ref('monthlyTurnoverThree')],
              'This value must be unique'
            )
        : $schema
  ),
  monthlyDiscountTwo: Yup.string(),
  // monthlyTurnoverThree: Yup.string().when(
  //   'volumeBasedDiscount',
  //   ([volumeBasedDiscount], $schema) =>
  //     volumeBasedDiscount === true
  //       ? $schema
  //           .required('This field is required')
  //           .notOneOf(
  //             [Yup.ref('monthlyTurnoverTwo'), Yup.ref('monthlyTurnoverOne')],
  //             'This value must be unique'
  //           )
  //       : $schema
  // ),
  billingCycleFrequency: Yup.string().required('Billing cycle is required'),
  paymentTermsInDays: Yup.number().required('Contract terms are required'),
  monthlyDiscountThree: Yup.string(),
});

interface PricingDisplayProps {
  isDesktop: boolean;
  register: UseFormRegister<ProposalFormValues>;
  getValues: UseFormGetValues<ProposalFormValues>;
  watch: any;
  setValue: any;
  match: Match | null;
  isOnShortlist: boolean;
  currencySymbol: string | undefined;
  errors: FieldErrors<ProposalFormValues>;
  priceField: string;
  label: string;
  warehousePrice: number | undefined;
  proposedValue: number | undefined;
  acceptedValue: number | undefined;
  percentage?: boolean;
}

function PricingDisplay({
  isDesktop,
  getValues,
  register,
  watch,
  setValue,
  match,
  isOnShortlist,
  currencySymbol,
  errors,
  priceField,
  label,
  warehousePrice,
  proposedValue,
  acceptedValue,
  percentage,
}: PricingDisplayProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      <Box sx={{ flex: '2 1 0%', marginRight: '1rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: isDesktop ? 'flex-end' : 'space-between',
            textAlign: isDesktop ? 'right' : 'left',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{label}:</Typography>
          <Typography
            variant='body1'
            style={{
              fontWeight: 'bold',
              marginLeft: '2rem',
            }}
          >
            {!percentage && (warehousePrice || acceptedValue) && currencySymbol}
            {!warehousePrice && !acceptedValue && '-'}
            {acceptedValue ?? warehousePrice}
            {percentage && warehousePrice && '%'}
          </Typography>
        </div>
      </Box>
      {isOnShortlist && (
        <>
          <Box sx={{ flex: '1 1 0%', marginRight: '1rem' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: isDesktop ? 'flex-end' : 'space-between',
                textAlign: isDesktop ? 'right' : 'left',
              }}
            >
              <Typography
                variant='body1'
                style={{
                  fontWeight: 'bold',
                  marginLeft: '2rem',
                  color: warehousePrice !== proposedValue ? 'red' : 'black',
                }}
              >
                {!percentage && proposedValue && currencySymbol}
                {!proposedValue && 'Requested'}
                {proposedValue}
                {percentage && proposedValue && '%'}
              </Typography>
            </div>
          </Box>
          <Box sx={{ flex: '1 1 0%' }}>
            <TextField
              variant='outlined'
              placeholder={`${warehousePrice ?? ''}`}
              {...register(`${priceField}`)}
              error={!!errors[priceField]}
              helperText={errors?.[priceField]?.message as string}
              disabled={
                getValues('volumeBasedDiscount') ||
                match?.isActionRequiredByShipper ||
                getValues(`${priceField}Unavailable`)
              }
            />
          </Box>
          <Box sx={{ flex: '1 1 0%' }} display='flex' justifyContent='center'>
            <Checkbox
              checked={watch(`${priceField}Unavailable`)}
              onChange={(event) => {
                match?.isActionRequiredByLsp &&
                  setValue(`${priceField}Unavailable`, event.target.checked);
                setValue(`${priceField}`, null);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export function ManagerServiceRatesSideDrawer({
  warehouse,
  match,
  isOpen,
  isOnShortlist,
  isProposing,
  isAccepting,
  comment,
  setIsOpen,
  handleProposal,
  handleDeleteMatch,
  handleAcceptMatch,
}: {
  warehouse: Warehouse;
  match: Match | null;
  isOpen: boolean;
  isOnShortlist: boolean;
  isProposing: boolean;
  isAccepting: boolean;
  comment: any;
  setIsOpen: (value: boolean) => void;
  handleProposal: (data: ProposalFormValues) => void;
  handleAcceptMatch: () => void;
  handleDeleteMatch: (matchId: string) => void;
}) {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(
    {}
  );

  const currencies = SUPPORTED_CURRENCY_GROUPS.flatMap(
    (currencyGroup) => currencyGroup.currencies
  );

  let currencySymbol: string | undefined;

  if (warehouse.currency) {
    currencySymbol = currencies.find(
      (currency) => currency.code === warehouse.currency
    )?.symbol;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
    watch,
  } = useForm<ProposalFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      storagePricePerWeek: undefined,
      storagePricePerWeekUnavailable: false,
      palletReceiptAndPutAwayPrice: undefined,
      palletReceiptAndPutAwayPriceUnavailable: false,
      palletPickingAndDespatchPrice: undefined,
      palletPickingAndDespatchPriceUnavailable: false,
      casePickingAndDespatchPrice: undefined,
      casePickingAndDespatchPriceUnavailable: false,
      itemPickingAndDespatchPrice: undefined,
      itemPickingAndDespatchPriceUnavailable: false,
      containerHandlingPrice: undefined,
      containerHandlingPriceUnavailable: false,
      demurragePricePerDay: undefined,
      demurragePricePerDayUnavailable: false,
      palletReturnsPrice: undefined,
      palletReturnsPriceUnavailable: false,
      itemReturnsPrice: undefined,
      itemReturnsPriceUnavailable: false,
      caseReturnsPrice: undefined,
      caseReturnsPriceUnavailable: false,
      auxiliaryServicePricePerManHour: undefined,
      auxiliaryServicePricePerManHourUnavailable: false,
      additionalSurchargesPercentage: undefined,
      additionalSurchargesPercentageUnavailable: false,
      volumeBasedDiscount: false,
      monthlyTurnoverOne: undefined,
      monthlyDiscountOne: undefined,
      monthlyTurnoverTwo: undefined,
      monthlyDiscountTwo: undefined,
      monthlyTurnoverThree: undefined,
      monthlyDiscountThree: undefined,
      billingCycleFrequency: 'Monthly',
      paymentTermsInDays: 30,
      desiredStartDate: dayjs(new Date()).format(),
      estimatedDurationInMonths: undefined,
      averageNumberOfInboundShipments: undefined,
      averageNumberOfOutboundShipments: undefined,
    },
  });

  const onSubmit = (
    data: ProposalFormValues,
    handler: (data: ProposalFormValues) => void
  ) => {
    handler(data);
  };

  useEffect(() => {
    clearErrors();
  }, [getValues('volumeBasedDiscount')]);

  useEffect(() => {
    console.log(match, 'match');
    if (match) {
      const values = {
        storagePricePerWeek:
          match.serviceRates?.storagePricePerWeek.lspProposedValue ??
          match.originalRates.storagePricePerWeek,
        storagePricePerWeekUnavailable:
          !match.originalRates.storagePricePerWeek &&
          match.serviceRates?.storagePricePerWeek.lspProposedValue === null,
        palletReceiptAndPutAwayPrice:
          match.serviceRates?.palletReceiptAndPutAwayPrice.lspProposedValue ??
          match.originalRates.palletReceiptAndPutAwayPrice,
        palletReceiptAndPutAwayPriceUnavailable:
          !match.originalRates.palletReceiptAndPutAwayPrice &&
          match.serviceRates?.palletReceiptAndPutAwayPrice.lspProposedValue ===
            null,
        palletPickingAndDespatchPrice:
          match.serviceRates?.palletPickingAndDespatchPrice.lspProposedValue ??
          match.originalRates.palletPickingAndDespatchPrice,
        palletPickingAndDespatchPriceUnavailable:
          !match.originalRates.palletPickingAndDespatchPrice &&
          match.serviceRates?.palletPickingAndDespatchPrice.lspProposedValue ===
            null,
        casePickingAndDespatchPrice:
          match.serviceRates?.casePickingAndDespatchPrice.lspProposedValue ??
          match.originalRates.casePickingAndDespatchPrice,
        casePickingAndDespatchPriceUnavailable:
          !match.originalRates.casePickingAndDespatchPrice &&
          match.serviceRates?.casePickingAndDespatchPrice.lspProposedValue ===
            null,
        itemPickingAndDespatchPrice:
          match.serviceRates?.itemPickingAndDespatchPrice.lspProposedValue ??
          match.originalRates.itemPickingAndDespatchPrice,
        itemPickingAndDespatchPriceUnavailable:
          !match.originalRates.itemPickingAndDespatchPrice &&
          match.serviceRates?.itemPickingAndDespatchPrice.lspProposedValue ===
            null,
        containerHandlingPrice:
          match.serviceRates?.containerHandlingPrice.lspProposedValue ??
          match.originalRates.containerHandlingPrice,
        containerHandlingPriceUnavailable:
          !match.originalRates.containerHandlingPrice &&
          match.serviceRates?.containerHandlingPrice.lspProposedValue === null,
        demurragePricePerDay:
          match.serviceRates?.demurragePricePerDay.lspProposedValue ??
          match.originalRates.demurragePricePerDay,
        demurragePricePerDayUnavailable:
          !match.originalRates.demurragePricePerDay &&
          match.serviceRates?.demurragePricePerDay.lspProposedValue === null,
        palletReturnsPrice:
          match.serviceRates?.palletReturnsPrice.lspProposedValue ??
          match.originalRates.palletReturnsPrice,
        palletReturnsPriceUnavailable:
          !match.originalRates.palletReturnsPrice &&
          match.serviceRates?.palletReturnsPrice.lspProposedValue === null,
        itemReturnsPrice:
          match.serviceRates?.itemReturnsPrice.lspProposedValue ??
          match.originalRates.itemReturnsPrice,
        itemReturnsPriceUnavailable:
          !match.originalRates.itemReturnsPrice &&
          match.serviceRates?.itemReturnsPrice.lspProposedValue === null,
        caseReturnsPrice:
          match.serviceRates?.caseReturnsPrice.lspProposedValue ??
          match.originalRates.caseReturnsPrice,
        caseReturnsPriceUnavailable:
          !match.originalRates.caseReturnsPrice &&
          match.serviceRates?.caseReturnsPrice.lspProposedValue === null,
        auxiliaryServicePricePerManHour:
          match.serviceRates?.auxiliaryServicePricePerManHour
            .lspProposedValue ??
          match.originalRates.auxiliaryServicePricePerManHour,
        auxiliaryServicePricePerManHourUnavailable:
          !match.originalRates.auxiliaryServicePricePerManHour &&
          match.serviceRates?.auxiliaryServicePricePerManHour
            .lspProposedValue === null,
        additionalSurchargesPercentage:
          match.serviceRates?.additionalSurchargesPercentage.lspProposedValue ??
          match.originalRates.additionalSurchargesPercentage,
        additionalSurchargesPercentageUnavailable:
          !match.originalRates.additionalSurchargesPercentage &&
          match.serviceRates?.additionalSurchargesPercentage
            .lspProposedValue === null,
        volumeBasedDiscount: match.volumeDiscounts?.length > 0,
        monthlyTurnoverOne: match.volumeDiscounts?.[0]?.maxTotalMonthlyTurnover,
        monthlyDiscountOne: match.isActionRequiredByLsp
          ? match.volumeDiscounts?.[0]?.shipperProposedDiscountPercentage
          : match.volumeDiscounts?.[0]?.lspProposedDiscountPercentage,
        monthlyTurnoverTwo: match.volumeDiscounts?.[1]?.maxTotalMonthlyTurnover,
        monthlyDiscountTwo: match.isActionRequiredByLsp
          ? match.volumeDiscounts?.[1]?.shipperProposedDiscountPercentage
          : match.volumeDiscounts?.[1]?.lspProposedDiscountPercentage,
        monthlyTurnoverThree:
          match.volumeDiscounts?.[2]?.maxTotalMonthlyTurnover,
        monthlyDiscountThree: match.isActionRequiredByLsp
          ? match.volumeDiscounts?.[2]?.shipperProposedDiscountPercentage
          : match.volumeDiscounts?.[2]?.lspProposedDiscountPercentage,
        billingCycleFrequency: match.billingCycleFrequency,
        paymentTermsInDays: match.paymentTermsInDays,
        desiredStartDate:
          match.desiredStartDate === '0001-01-01'
            ? dayjs().format()
            : dayjs(match.desiredStartDate).format(),
        estimatedDurationInMonths: match.estimatedDurationInMonths,
        averageNumberOfInboundShipments:
          match.averageNumberOfInboundShipmentsPerWeek,
        averageNumberOfOutboundShipments:
          match.averageNumberOfOutboundShipmentsPerWeek,
      };
      setInitialValues(values);
      reset(values);
    }
  }, [match]);

  const formValues = watch();

  const hasFormChanged = () => {
    return Object.keys(initialValues).some((key) => {
      if (
        key === 'comment' ||
        key === 'monthlyDiscountOne' ||
        key === 'monthlyDiscountTwo' ||
        key === 'monthlyDiscountThree' ||
        key === 'monthlyTurnoverOne' ||
        key === 'monthlyTurnoverTwo' ||
        key === 'monthlyTurnoverThree' ||
        key === 'billingCycleFrequency' ||
        key === 'paymentTermsInDays'
      ) {
        return false;
      }
      // eslint-disable-next-line eqeqeq
      return initialValues[key] != formValues[key];
    });
  };

  return (
    <Drawer
      hideBackdrop
      anchor={isDesktop ? 'right' : 'bottom'}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        '&.MuiDrawer-root': {
          left: 'unset',
        },
        '.MuiDrawer-paper': {
          backgroundColor: 'var(--colors-gray2)',
          color: 'var(--colors-gray12)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          // eslint-disable-next-line no-nested-ternary
          width: isDesktop ? (isOnShortlist ? '60rem' : '32rem') : '100%',
          p: 16,
          textAlign: isDesktop ? 'right' : 'left',
        }}
      >
        <Stack direction='row' width='100%' justifyContent='space-between'>
          <IconButton
            aria-label='close'
            onClick={() => setIsOpen(false)}
            size='medium'
            sx={{
              height: '2.5rem',
              color: (theme) => theme.palette.grey[500],
              mb: '1rem',
              ...(!isDesktop && {
                alignSelf: 'flex-end',
              }),
            }}
          >
            <CloseIcon />
          </IconButton>
          {isOnShortlist && (
            <Typography fontSize='1rem'>
              You can either accept the Proposed Rates or counter propose. If
              you counter propose, the other party will have the opportunity to
              accept or counter propose again.
            </Typography>
          )}
        </Stack>
        {isOnShortlist && (
          <Typography fontSize='1rem' marginLeft='auto'>
            Negotiating with: <strong>{match?.userBusinessName}</strong>
          </Typography>
        )}
        <Box sx={{ p: 8, width: '100%' }}>
          <form id='proposalForm'>
            <Stack gap={24}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 8,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <Box sx={{ flex: '2 1 0%', marginRight: '1rem' }}>
                    <Typography variant='h6'>
                      {match?.matchStatus === 'Reviewing T&Cs' ||
                      match?.matchStatus === 'Contracted'
                        ? 'Agreed Service Rates'
                        : 'Service Rates'}
                    </Typography>
                  </Box>
                  {isOnShortlist && (
                    <>
                      <Box sx={{ flex: '1 1 0%' }}>
                        <Typography variant='h6'>Update</Typography>
                      </Box>
                      <Box sx={{ flex: '1 1 0%' }}>
                        <Typography variant='h6'>Counter</Typography>
                      </Box>
                      <Box sx={{ flex: '1 1 0%' }} textAlign='center'>
                        <Typography variant='h6'>
                          Service Unavailable
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label={`Storage (per
                  ${
                    warehouse.storageCapacityUnitOfMeasure === 'Pallets'
                      ? 'pallet-week'
                      : warehouse.storageCapacityUnitOfMeasure.toLowerCase()
                  })`}
                  priceField='storagePricePerWeek'
                  warehousePrice={warehouse.storageCapacityUnitOfMeasure === 'Pallets' ? warehouse.palletStoragePricePerWeek : warehouse.storageCapacityUnitOfMeasure === 'Square Metre' ? warehouse.squareMetreStoragePricePerWeek : warehouse.squareFeetStoragePricePerWeek}
                  proposedValue={
                    match?.serviceRates?.storagePricePerWeek
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.storagePricePerWeek.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Receipt and putaway (per pallet)'
                  priceField='palletReceiptAndPutAwayPrice'
                  warehousePrice={
                    warehouse.palletReceiptAndPutAwayPrice
                  }
                  proposedValue={
                    match?.serviceRates?.palletReceiptAndPutAwayPrice
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.palletReceiptAndPutAwayPrice
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Pallet picking and despatch (per pallet)'
                  priceField='palletPickingAndDespatchPrice'
                  warehousePrice={
                    warehouse.palletPickingAndDespatchPrice
                  }
                  proposedValue={
                    match?.serviceRates?.palletPickingAndDespatchPrice
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.palletPickingAndDespatchPrice
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Case picking and despatch (per case)'
                  priceField='casePickingAndDespatchPrice'
                  warehousePrice={
                    warehouse.casePickingAndDespatchPrice
                  }
                  proposedValue={
                    match?.serviceRates?.casePickingAndDespatchPrice
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.casePickingAndDespatchPrice
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Item picking and despatch (per item)'
                  priceField='itemPickingAndDespatchPrice'
                  warehousePrice={
                    warehouse.itemPickingAndDespatchPrice
                  }
                  proposedValue={
                    match?.serviceRates?.itemPickingAndDespatchPrice
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.itemPickingAndDespatchPrice
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Container handling (per container)'
                  priceField='containerHandlingPrice'
                  warehousePrice={warehouse.containerHandlingPrice}
                  proposedValue={
                    match?.serviceRates?.containerHandlingPrice
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.containerHandlingPrice.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Demurrage (per consignment day)'
                  priceField='demurragePricePerDay'
                  warehousePrice={warehouse.demurragePricePerDay}
                  proposedValue={
                    match?.serviceRates?.demurragePricePerDay
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.demurragePricePerDay.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label={`Returns (per
                  ${
                    warehouse.storageCapacityUnitOfMeasure === 'Pallets'
                      ? 'pallet-week'
                      : warehouse.storageCapacityUnitOfMeasure.toLowerCase()
                  })`}
                  priceField='palletReturnsPrice'
                  warehousePrice={warehouse.palletReturnsPrice}
                  proposedValue={
                    match?.serviceRates?.palletReturnsPrice.shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.palletReturnsPrice.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Returns (per item)'
                  priceField='itemReturnsPrice'
                  warehousePrice={warehouse.itemReturnsPrice}
                  proposedValue={
                    match?.serviceRates?.itemReturnsPrice.shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.itemReturnsPrice.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Returns (per case)'
                  priceField='caseReturnsPrice'
                  warehousePrice={warehouse.caseReturnsPrice}
                  proposedValue={
                    match?.serviceRates?.caseReturnsPrice.shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.caseReturnsPrice.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Auxiliary services (per man-hour)'
                  priceField='auxiliaryServicePricePerManHour'
                  warehousePrice={
                    warehouse.auxiliaryServicePricePerManHour
                  }
                  proposedValue={
                    match?.serviceRates?.auxiliaryServicePricePerManHour
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.auxiliaryServicePricePerManHour
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  label='Surcharges (additional %-ge to the base)'
                  priceField='additionalSurchargesPercentage'
                  warehousePrice={
                    warehouse.additionalSurchargesPercentage
                  }
                  proposedValue={
                    match?.serviceRates?.additionalSurchargesPercentage
                      .shipperProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.additionalSurchargesPercentage
                      .acceptedValue
                  }
                  percentage
                />
              </Box>
              {isOnShortlist && (
                <>
                  <div>
                    <p className='mb-4'>Disclaimer:</p>
                    <p className='mb-4'>
                      All prices listed above are exclusive of VAT and FLOX
                      transaction fees. FLOX transaction fees for the services
                      are as follows:
                    </p>
                    <ul>
                      <li>Storage: 0%</li>
                      <li>Handling: 0%</li>
                      <li>Other Services: 0%</li>
                      <li>Haulage (FTL/LTL): 0%</li>
                      <li>Urban Distribution: 0%</li>
                      <li>
                        Additional financial transaction fees vary between 0%
                        and 1.75%, depending on the selected payment option.
                      </li>
                    </ul>
                  </div>
                  <InputGroup
                    direction='row'
                    css={{
                      gap: '$space16',
                      padding: '$space2',
                      justifyContent: isDesktop ? 'flex-end' : 'left',
                    }}
                  >
                    <Controller
                      name='volumeBasedDiscount'
                      control={control}
                      render={({ field }) => {
                        return (
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                  ) => {
                                    setValue('volumeBasedDiscount', checked, {
                                      shouldValidate: true,
                                    });
                                  }}
                                  name={field.name}
                                  disabled={match?.isActionRequiredByShipper}
                                />
                              }
                              label='Apply volume-based discount structure instead'
                            />
                          </FormGroup>
                        );
                      }}
                    />
                  </InputGroup>
                  {getValues('volumeBasedDiscount') && (
                    <Stack direction='column' gap={12} alignItems='end'>
                      <Stack direction='row' gap={48}>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>
                            Total monthly turnover up to:
                          </Typography>
                          <TextField
                            variant='outlined'
                            placeholder={`${currencySymbol}#,000.00`}
                            {...register('monthlyTurnoverOne')}
                            error={!!errors.monthlyTurnoverOne}
                            helperText={
                              errors?.monthlyTurnoverOne?.message as string
                            }
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByShipper
                            }
                          />
                        </Stack>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>Discount:</Typography>
                          <TextField
                            variant='outlined'
                            placeholder='#.#%'
                            {...register('monthlyDiscountOne')}
                            error={!!errors.monthlyDiscountOne}
                            helperText={
                              errors?.monthlyDiscountOne?.message as string
                            }
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByShipper
                            }
                          />
                        </Stack>
                      </Stack>
                      <Stack direction='row' gap={48}>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>
                            Between £{getValues().monthlyTurnoverOne} and:
                          </Typography>
                          <TextField
                            variant='outlined'
                            placeholder={`${currencySymbol}#0,000.00`}
                            {...register('monthlyTurnoverTwo')}
                            error={!!errors.monthlyTurnoverTwo}
                            helperText={
                              errors?.monthlyTurnoverTwo?.message as string
                            }
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByShipper
                            }
                          />
                        </Stack>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>Discount:</Typography>
                          <TextField
                            variant='outlined'
                            placeholder='#.#%'
                            {...register('monthlyDiscountTwo')}
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByShipper
                            }
                          />
                        </Stack>
                      </Stack>
                      <Stack direction='row' gap={48}>
                        <Stack
                          direction='row'
                          gap={12}
                          alignItems='center'
                          sx={{ marginRight: '9em' }}
                        >
                          <Typography variant='body1'>above:</Typography>
                          <Typography variant='body1'>
                            £{getValues().monthlyTurnoverTwo}
                          </Typography>
                        </Stack>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>Discount:</Typography>
                          <TextField
                            variant='outlined'
                            placeholder='#.#%'
                            {...register('monthlyDiscountThree')}
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByShipper
                            }
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  )}

                  <Stack direction='column' gap={12} alignItems='end'>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Billing Cycle (days)
                      </Typography>
                      <Select {...register('billingCycleFrequency')}>
                        <option value='Weekly'>Weekly</option>
                        <option value='Every Two Weeks'>Every Two Weeks</option>
                        <option value='Monthly'>Monthly</option>
                      </Select>
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Payment Terms (days)
                      </Typography>
                      <Select {...register('paymentTermsInDays')}>
                        <option value={7}>7</option>
                        <option value={14}>14</option>
                        <option value={30}>30</option>
                      </Select>
                    </Stack>
                  </Stack>

                  <Stack direction='column' gap={12} alignItems='end'>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Desired Start Date:
                      </Typography>
                      <Typography variant='body1'>
                        {match?.desiredStartDate}
                      </Typography>
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Storage needed (in{' '}
                        {warehouse.storageCapacityUnitOfMeasure.toLowerCase()},
                        per week):
                      </Typography>
                      <Typography variant='body1'>
                        {match?.averageStorageQuantityPerWeek}
                      </Typography>
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Estimated duration in months:
                      </Typography>
                      <Typography variant='body1'>
                        {match?.estimatedDurationInMonths}
                      </Typography>
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Average number of inbound shipments (per week):
                      </Typography>
                      <Typography variant='body1'>
                        {match?.averageNumberOfInboundShipmentsPerWeek}
                      </Typography>
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Average number of outbound shipments (per week):
                      </Typography>
                      <Typography variant='body1'>
                        {match?.averageNumberOfOutboundShipmentsPerWeek}
                      </Typography>
                    </Stack>
                    <Stack direction='column' gap={12} width='100%' mt={12}>
                      {/* <Typography variant='body1'>
                        {match?.comments &&
                          match?.comments?.length > 0 &&
                          `${match?.comments.pop()?.createdByUserName}: ${
                            match?.comments.pop()?.commentText
                          }`}
                      </Typography> */}
                      <Typography variant='body1'>
                        {comment &&
                          `${comment.createdByUserName}: ${comment.commentText}`}
                      </Typography>
                      <TextField
                        variant='outlined'
                        placeholder='Comments'
                        multiline
                        minRows={3}
                        sx={{ width: '100%' }}
                        {...register('comment')}
                        error={!!errors.comment}
                        helperText={errors?.comment?.message as string}
                        disabled={match?.isActionRequiredByShipper}
                      />
                    </Stack>
                  </Stack>
                  <Typography variant='body1'>
                    *) Surcharges may be applied for Rush Order/Expedited
                    Service, Peak Season, Delay/Detention, Cancellation, Late
                    Payment, and other extraordinary service requests.
                  </Typography>
                  <Stack
                    direction='row'
                    gap={12}
                    justifyContent={isDesktop ? 'flex-end' : 'left'}
                  >
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ textTransform: 'none', marginTop: '2rem' }}
                      disabled={
                        isProposing ||
                        isAccepting ||
                        match?.isActionRequiredByShipper
                      }
                      onClick={() => handleDeleteMatch(match?.id as string)}
                    >
                      Cancel negotiations
                    </Button>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ textTransform: 'none', marginTop: '2rem' }}
                      onClick={handleSubmit((data) =>
                        onSubmit(data, handleProposal)
                      )}
                      disabled={
                        isProposing ||
                        isAccepting ||
                        match?.isActionRequiredByShipper
                      }
                    >
                      {isProposing
                        ? 'Proposing...'
                        : match?.isActionRequiredByShipper
                        ? 'Proposed'
                        : 'Counter Propose'}
                    </Button>
                    <Button
                      variant='contained'
                      size='large'
                      sx={{ textTransform: 'none', marginTop: '2rem' }}
                      disabled={
                        isProposing ||
                        isAccepting ||
                        match?.isActionRequiredByShipper ||
                        hasFormChanged()
                      }
                      onClick={handleAcceptMatch}
                    >
                      {isAccepting ? 'Accepting...' : 'Accept Proposed'}
                    </Button>
                  </Stack>
                </>
              )}
              {match?.matchStatus === 'Contracted' && (
                <Stack
                  direction='row'
                  gap={12}
                  justifyContent={isDesktop ? 'flex-end' : 'left'}
                >
                  <Button
                    variant='contained'
                    size='large'
                    sx={{
                      textTransform: 'none',
                      marginTop: '2rem',
                    }}
                    onClick={() =>
                      window.open(match?.legalDocument.url, '_blank')
                    }
                  >
                    View contract
                  </Button>
                </Stack>
              )}
            </Stack>
          </form>
        </Box>
      </Box>
    </Drawer>
  );
}
