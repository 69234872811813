import {
  BillingCycle,
  Payment as PaymentType,
} from '@views/Invoice/invoiceTypes';
import { View } from '@views/View';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Heading } from '@components/Heading';
import {
  Modal,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from '@components/Modal';
import { Button } from '@components/Button';
import { Divider } from '@mui/material';
import { useAuthHttp } from '../../hooks';
import { INVOICE_LIST } from '../../constants';
import { TransactionSummary } from './TransactionSummary';
import { PaymentDetails } from './PaymentDetails';
import { UserRoleService } from '../../services';

export function Payment() {
  const authHttp = useAuthHttp();
  const { isWarehouseClient } = UserRoleService();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const hasJustPaid = !!searchParams.get('justPaid') && isWarehouseClient;
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [payment, setPayment] = useState<PaymentType>();
  const [billingCycles, setBillingCycles] = useState<BillingCycle[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBillingCycles = async () => {
    setIsLoading(true);
    try {
      const { data } = await authHttp.post(
        INVOICE_LIST,
        {
          statuses: ['Paid'], // TODO: Add payment pending status?
          paymentId: id,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const relevantBillingCycles: BillingCycle[] = [];
      if (data.billingCycles) {
        data.billingCycles.forEach((billingCycle: BillingCycle) => {
          // TODO: Temporary measure while BE query is not working.
          // When that is fixed, ListBillingCycles will only return billingCycles with an invoice containing this payment
          // So we can look at the first billingCycle and not need to loop
          const paymentMatch = billingCycle.invoice.payments.find(
            (p: { id: string | undefined }) => p.id === id
          );

          if (paymentMatch) {
            setPayment(paymentMatch);
            relevantBillingCycles.push(billingCycle);
          }
        });
        setBillingCycles(relevantBillingCycles);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBillingCycles();
    if (hasJustPaid) {
      setIsConfirmationModalOpen(true);
    }
  }, []);

  return (
    <>
      <View>
        {payment && (
          <>
            <Heading as='h2' size='xs' className='mb-4'>
              Payment
              {/* - {payment.status} TODO: Add this once we've implemented Yapily web socket */}
            </Heading>
            <TransactionSummary payment={payment} />
            <Divider />
            <PaymentDetails billingCycles={billingCycles} />
          </>
        )}
        {!payment && <div>No payment</div>}
      </View>
      <Modal
        open={isConfirmationModalOpen}
        onOpenChange={setIsConfirmationModalOpen}
      >
        <ModalContainer>
          <ModalHeader title='Payment Confirmation' />
          <ModalContent>
            <div>Your payment has been submitted successfully.</div>
            <div>It may take up to 3 working days to process your payment.</div>
            {/* <div>
              This page will update once the payment has been fully processed.
            </div> */}
            {/* TODO: Implement socket */}
            <div className='d-flex flex flex-row gap-x-2 justify-content-end mt-6'>
              {/* <Button
                variant='primary'
                size='lg'
                onClick={() => navigate(`/invoice/pay`)}
              >
                Pay Another Invoice
              </Button> */}
              <Button
                variant='primary'
                size='lg'
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Close
              </Button>
            </div>
          </ModalContent>
        </ModalContainer>
      </Modal>
    </>
  );
}
