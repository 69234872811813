import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Stack } from '@mui/material';
import { Heading } from '@components/Heading';
import { Button } from '@components/Button';
import { useAuthHttp } from '../../hooks';
import { MAKE_PAYMENT } from '../../constants';

export function UpdatePaymentConsent() {
  const authHttp = useAuthHttp();
  const [isUpdating, setIsUpdating] = useState(true);
  const [hasErrored, setHasErrored] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const handleUpdatePaymentConsent = async () => {
    setHasErrored(false);
    const oneTimeToken = searchParams.get('one-time-token');
    const paymentIdempotencyId = searchParams.get('paymentIdempotencyId');
    const reference = searchParams.get('reference');
    const billingCycleIds = searchParams.getAll('billingCycleId');

    try {
      // eslint-disable-next-line no-alert
      const { data } = await authHttp.post(
        MAKE_PAYMENT,
        {
          oneTimeToken,
          paymentIdempotencyId,
          reference,
          billingCycleIds,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (data.payment) {
        navigate(`/payment/${data.payment.id}?justPaid=true`);
      }
    } catch (error) {
      setHasErrored(true);
      let returnUrl = `/invoice/pay?error`;
      billingCycleIds.forEach((id) => {
        returnUrl += `?billingCycleId=${id}`;
      });

      navigate(returnUrl);
    }
  };

  useEffect(() => {
    handleUpdatePaymentConsent();
  }, []);

  if (hasErrored) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Heading as='h3' size='xs' className='mb-4'>
          Failed to update bank account consent. Please try again.
        </Heading>
        <Button onClick={handleUpdatePaymentConsent}>Retry</Button>
      </Box>
    );
  }

  if (isUpdating) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <Stack alignItems='center' spacing={16}>
          <Heading as='h3' size='xs'>
            Authorising payment consent...
          </Heading>
          <CircularProgress size={80} />
        </Stack>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh',
      }}
    >
      <Heading as='h3' size='xs'>
        Failed to update payment consent. Please try again.
      </Heading>
    </Box>
  );
}
