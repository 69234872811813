import { BillingCycle, InvoiceLine, VAT_RATE } from './invoiceTypes';

export function getVatCost(amount: number) {
  return (amount * VAT_RATE) / 100;
}

function getInvoiceLineCost(line: InvoiceLine) {
  return line.amount;
}

export function getInvoiceLineVatCost(line: InvoiceLine) {
  return getVatCost(line.amount);
}

export function getBillingCycleCost(billingCycle: BillingCycle): number {
  return billingCycle?.invoice?.lines
    ? billingCycle.invoice.lines.reduce(
        (sum, line) => sum + getInvoiceLineCost(line),
        0
      )
    : 0;
}

export function getBillingCycleVatCost(billingCycle: BillingCycle): number {
  return billingCycle?.invoice?.lines
    ? billingCycle.invoice.lines.reduce(
        (sum, line) => sum + getInvoiceLineVatCost(line),
        0
      )
    : 0;
}
