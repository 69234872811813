import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { View } from '@views/View';
import { useMediaQuery } from 'usehooks-ts';
import { Heading } from '@components/Heading';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import { Stack } from '@mui/material';
import { additionalServicesOptions, BillingCycle } from './invoiceTypes';
import { INVOICE_LIST } from '../../constants';
import { useAuthHttp } from '../../hooks';
import { classNames } from '../../utils/classes';
import { UserRoleService } from '../../services';
import { useAuth } from '../../context';
import { getBillingCycleCost, getBillingCycleVatCost } from './invoiceHelper';

export function InvoiceList() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const authHttp = useAuthHttp();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { isWarehouseClient } = UserRoleService();

  const [billingCycles, setBillingCycles] = useState<BillingCycle[]>([]);
  const [isInvoicesLoading, setIsInvoicesLoading] = useState(false);

  const fetchInvoices = async () => {
    setIsInvoicesLoading(true);
    try {
      const { data } = await authHttp.post(
        INVOICE_LIST,
        {
          statuses: isWarehouseClient(user) ? ['Finalised', 'Paid'] : '',
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        setBillingCycles(data.billingCycles);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsInvoicesLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <View>
      <div className='flex flex-col h-[90vh]'>
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Invoices
        </Heading>
        <div className='mt-8 overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-white sticky top-0'>
              <tr>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  ID
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Customer Name
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Issue Date
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Services
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Amount
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Due Date
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {isInvoicesLoading && (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <InfiniteScrollLoading
                      key='invoice-loader'
                      loadingMessage='Loading invoices...'
                      sx={{ gridColumn: '1 / -1' }}
                    />
                  </td>
                </tr>
              )}
              {billingCycles.length === 0 && !isInvoicesLoading && (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <Stack
                      direction='column'
                      alignItems='center'
                      spacing={2}
                      sx={{ gridColumn: '1 / -1' }}
                    >
                      <span className='text-gray-900 text-md font-semibold'>
                        No invoices found.
                      </span>
                    </Stack>
                  </td>
                </tr>
              )}
              {billingCycles.map((billingCycle) => {
                const subTotalAmount = getBillingCycleCost(billingCycle);
                const subTotalVatAmount = getBillingCycleVatCost(billingCycle);
                const totalAmount = subTotalAmount + subTotalVatAmount;

                const descriptions = billingCycle.invoice.lines
                  .map((line) => {
                    const matchingService = additionalServicesOptions.find(
                      (option) => option.value === line.description
                    );
                    return matchingService
                      ? matchingService.label
                      : line.description;
                  })
                  .join(', ');

                const date = new Date(billingCycle.invoice.createdAt);

                return (
                  <tr
                    key={billingCycle.invoice.id}
                    className={classNames(
                      'border-gray-200',
                      'border-t',
                      'hover:bg-gray-50',
                      'transition-colors',
                      'duration-200',
                      'ease-in-out',
                      'cursor-pointer'
                    )}
                    onClick={() => {
                      navigate(`/invoice/${billingCycle.id}`);
                    }}
                  >
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                      {billingCycle.invoice.invoiceNumber}
                    </td>
                    <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
                      {billingCycle.shipperBusinessName}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                      {`${date.getDate().toString().padStart(2, '0')}/${(
                        date.getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')}/${date.getFullYear()}`}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                      {descriptions.substring(0, 150)}
                      {descriptions.length > 150 ? '...' : ''}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                      {billingCycle.invoice.lines[0].currencySymbol}
                      {totalAmount.toFixed(2)}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                      {billingCycle.invoice.dueDate
                        ? moment(billingCycle.invoice.dueDate).format(
                            'DD/MM/YYYY'
                          )
                        : 'N/A'}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                      {billingCycle.invoice.status}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </View>
  );
}
