import { NotificationType } from '@components/Notification/Notification';
import { BillingCycle } from '@views/Invoice/invoiceTypes';
import { Match } from '@views/WarehouseDetails/WarehouseDetails';
import { atom } from 'jotai';

// General
export const versionAtom = atom<string>('0.0.0');
export const backendVersionAtom = atom<string>('0.0.0');
export const hasResponseErrorAtom = atom<boolean>(false);
export const isPublicPageAtom = atom<boolean>(false);

// Noticeboard
export const isInboundSidebarOpenAtom = atom<boolean>(false);
export const isOutboundSidebarOpenAtom = atom<boolean>(false);
export const isInvoiceSidebarOpenAtom = atom<boolean>(false);
export const selectedInboundProcessIdAtom = atom<string>('');
export const selectedOutboundProcessIdAtom = atom<string>('');
export const selectedInvoiceIdAtom = atom<string>('');
export const billingCyclesAtom = atom<BillingCycle[]>([]);
export const paidBillingCyclesAtom = atom<BillingCycle[]>([]);

// Warehouse
export const isWarehouseModalOpenAtom = atom<boolean>(false);

// Fleet
export const isVehicleModalOpenAtom = atom<boolean>(false);
export const isVehicleSummaryModalOpenAtom = atom<boolean>(false);
export const isUploadFleetModalOpenAtom = atom<boolean>(false);
export const editVehicleIdAtom = atom<string>('');

// Pricing List
export const isRatecardModalOpenAtom = atom<boolean>(false);
export const isRatecardEditModalOpenAtom = atom<boolean>(false);
export const ratecardPricingTypeAtom = atom<string>('');

// Shipments
export const selectedShipmentNoteNumberAtom = atom<string>('');

// Product
export const isUploadProductsModalOpenAtom = atom<boolean>(false);

// Fleet availability
export const isPartialAvailabilityModalOpenAtom = atom<boolean>(false);
export const partialAvaiabilityVehicleIdAtom = atom<string>('');
export const fullCapacityVehiclesTomorrowAtom = atom<string[]>([]);
export const notAvailableVehiclesTomorrowAtom = atom<string[]>([]);
export const fullCapacityVehiclesDayAfterTomorrowAtom = atom<string[]>([]);
export const notAvailableVehiclesDayAfterTomorrowAtom = atom<string[]>([]);
export const tomorrowOrDayAfterAtom = atom<string>('tomorrow');
export const createdVehicleAvailabilityIdsAtom = atom<
  { vehicleId: string; date: string }[]
>([]);
export const vehicleAvailabilityIdsAtom = atom<
  { availabilityId: string; vehicleId: string; date: string }[]
>([]);

// Customer Master
export const isCustomerModalOpenAtom = atom<boolean>(false);

// Customer Master
export const isSupplierModalOpenAtom = atom<boolean>(false);

// Matching
export const matchAtom = atom<Match | null>(null);

// Business Profile
export const businessProfileAtom = atom<Record<string, any> | null>(null);

// Notifications
export const notificationsAtom = atom<NotificationType[]>([]);
export const showNotificationAtom = atom<boolean>(false);
