import moment from 'moment';
import { Match } from '@views/WarehouseDetails/WarehouseDetails';
import {
  BillingCycle,
  InvoiceLine,
  VAT_RATE,
  additionalServicesOptions,
} from './invoiceTypes';
import { classNames } from '../../utils/classes';
import {
  InvoiceTableEditSection,
  InvoiceTableEditSectionProps,
} from './InvoiceTableEditSection';
import { getInvoiceLineVatCost } from './invoiceHelper';

export function InvoiceTable({
  billingCycle,
  invoiceLines,
  match,
  subTotalAmount,
  subTotalVatAmount,
  editSectionProps,
}: {
  billingCycle: BillingCycle;
  invoiceLines: InvoiceLine[];
  match: Match | undefined;
  subTotalAmount: number;
  subTotalVatAmount: number;
  editSectionProps: InvoiceTableEditSectionProps | null;
}) {
  const startDate = moment(billingCycle.startDate);
  const endDate = moment(billingCycle.endDate);

  const isEditable = !!editSectionProps;

  return (
    <div className='mt-8 overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
      <table className='min-w-full divide-y divide-gray-300'>
        <thead className='bg-white sticky top-0'>
          <tr>
            <th
              scope='col'
              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
            >
              #
            </th>
            <th
              scope='col'
              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
            >
              Service provided: for the period from{' '}
              {startDate.format('DD/MM/YY')} to {endDate.format('DD/MM/YY')}
            </th>
            <th
              scope='col'
              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
            >
              Amount
            </th>
            <th
              scope='col'
              className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
            >
              VAT @{VAT_RATE}%
            </th>
            <th
              scope='col'
              className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
            >
              <span className='sr-only'>Actions</span>
            </th>
          </tr>
        </thead>
        <tbody className='bg-white'>
          {invoiceLines.map((line) => {
            const vatAmount = getInvoiceLineVatCost(line);

            const matchingService = additionalServicesOptions.find(
              (option) => option.value === line.description
            );

            return (
              <tr
                key={billingCycle.invoice.id}
                className={classNames(
                  'border-gray-200',
                  'border-t',
                  'hover:bg-gray-50',
                  'transition-colors',
                  'duration-200',
                  'ease-in-out'
                )}
              >
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                  {line.lineNumber}
                </td>
                <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
                  {matchingService ? matchingService.label : line.description}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                  {line.currencySymbol}
                  {line.amount.toFixed(2)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                  {line.currencySymbol}
                  {vatAmount.toFixed(2)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                  <span className='sr-only' />
                </td>
              </tr>
            );
          })}
          {isEditable && (
            <InvoiceTableEditSection
              billingCycle={billingCycle}
              match={match}
              {...editSectionProps}
            />
          )}
          <tr
            className={classNames(
              'border-gray-200',
              'border-t',
              'hover:bg-gray-50',
              'transition-colors',
              'duration-200',
              'ease-in-out'
            )}
          >
            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
              <span className='sr-only'>Line Number</span>
            </td>
            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 text-right'>
              Sub Total:
            </td>
            <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
              {billingCycle.invoice.lines[0].currencySymbol}
              {subTotalAmount.toFixed(2)}
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
              {billingCycle.invoice.lines[0].currencySymbol}
              {subTotalVatAmount.toFixed(2)}
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
              <span className='sr-only' />
            </td>
          </tr>
          <tr
            className={classNames(
              'border-gray-200',
              'border-t',
              'hover:bg-gray-50',
              'transition-colors',
              'duration-200',
              'ease-in-out'
            )}
          >
            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
              <span className='sr-only'>Line Number</span>
            </td>
            <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 text-right'>
              Grand Total:
            </td>
            <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
              {billingCycle.invoice.lines[0].currencySymbol}
              {(subTotalAmount + subTotalVatAmount).toFixed(2)}
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
              <span className='sr-only' />
            </td>
            <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
              <span className='sr-only' />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
