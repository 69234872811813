/* eslint-disable no-nested-ternary */
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useMediaQuery } from 'usehooks-ts';
import CloseIcon from '@mui/icons-material/Close';
import { InputGroup } from '@components/InputGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Controller,
  FieldErrors,
  UseFormGetValues,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { Select } from '@headlessui/react';
// eslint-disable-next-line import/no-cycle
import { Match, Warehouse } from './WarehouseDetails';
import { SUPPORTED_CURRENCY_GROUPS } from '../../constants';

export type ProposalFormValues = {
  [key: string]: any;
  storagePricePerWeek: number;
  storagePricePerWeekUnavailable: boolean;
  palletReceiptAndPutAwayPrice: number;
  palletReceiptAndPutAwayPriceUnavailable: boolean;
  palletPickingAndDespatchPrice: number;
  palletPickingAndDespatchPriceUnavailable: boolean;
  casePickingAndDespatchPrice: number;
  casePickingAndDespatchPriceUnavailable: boolean;
  itemPickingAndDespatchPrice: number;
  itemPickingAndDespatchPriceUnavailable: boolean;
  containerHandlingPrice: number;
  containerHandlingPriceUnavailable: boolean;
  demurragePricePerDay: number;
  demurragePricePerDayUnavailable: boolean;
  palletReturnsPrice: number;
  palletReturnsPriceUnavailable: boolean;
  itemReturnsPrice: number;
  itemReturnsPriceUnavailable: boolean;
  caseReturnsPrice: number;
  caseReturnsPriceUnavailable: boolean;
  auxiliaryServicePricePerManHour: number;
  auxiliaryServicePricePerManHourUnavailable: boolean;
  additionalSurchargesPercentage: number;
  additionalSurchargesPercentageUnavailable: boolean;
  volumeBasedDiscount: boolean;
  monthlyTurnoverOne: number;
  monthlyDiscountOne: number;
  monthlyTurnoverTwo: number;
  monthlyDiscountTwo: number;
  monthlyTurnoverThree: number;
  monthlyDiscountThree: number;
  billingCycleFrequency: string;
  paymentTermsInDays: number;
  desiredStartDate: string;
  estimatedDurationInMonths: number;
  averageStorageQuantityPerWeek: number;
  averageNumberOfInboundShipments: number;
  averageNumberOfOutboundShipments: number;
  comment: string;
};

interface PricingDisplayProps {
  isDesktop: boolean;
  register: UseFormRegister<ProposalFormValues>;
  getValues: UseFormGetValues<ProposalFormValues>;
  watch: any;
  setValue: any;
  match: Match | null;
  isOnShortlist: boolean;
  currencySymbol: string | undefined;
  errors: FieldErrors<ProposalFormValues>;
  priceField: string;
  label: string;
  warehousePrice: number | undefined;
  shipperProposedValue: number | undefined;
  proposedValue: number | undefined;
  acceptedValue: number | undefined;
  percentage?: boolean;
}

function PricingDisplay({
  isDesktop,
  getValues,
  register,
  watch,
  setValue,
  match,
  isOnShortlist,
  currencySymbol,
  errors,
  priceField,
  label,
  warehousePrice,
  shipperProposedValue,
  proposedValue,
  acceptedValue,
  percentage,
}: PricingDisplayProps) {
  const hasReceivedProposal = (): boolean => {
    if (match && match.serviceRates) {
      return Object.values(match.serviceRates).some(
        (rate) => rate.lspProposedValue !== null
      );
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      <Box sx={{ flex: '1 1 0%', marginRight: '1rem' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: isDesktop ? 'flex-end' : 'space-between',
            textAlign: isDesktop ? 'right' : 'left',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{label}:</Typography>
          <Typography
            variant='body1'
            style={{
              fontWeight: 'bold',
              marginLeft: '2rem',
              color:
                shipperProposedValue !== proposedValue && !acceptedValue
                  ? 'red'
                  : 'black',
            }}
          >
            {!percentage && (warehousePrice || proposedValue) && currencySymbol}
            {acceptedValue ??
              (proposedValue ||
                (warehousePrice === null ? '-' : warehousePrice))}
            {percentage && warehousePrice && '%'}
          </Typography>
        </div>
      </Box>
      {isOnShortlist && (
        <Box sx={{ flex: '1 1 0%' }}>
          {warehousePrice === null &&
            !proposedValue &&
            !match?.isActionRequiredByLsp &&
            !match?.isActionRequiredByShipper &&
            '-'}
          {warehousePrice === null &&
            !proposedValue &&
            match?.isActionRequiredByLsp &&
            !hasReceivedProposal() &&
            'Requested'}
          {warehousePrice === null &&
            !proposedValue &&
            match?.isActionRequiredByLsp &&
            hasReceivedProposal() &&
            'Service Unavailable'}
          {warehousePrice === null &&
            !proposedValue &&
            match?.isActionRequiredByShipper &&
            'Service Unavailable'}
          {(warehousePrice !== null || proposedValue) && (
            <TextField
              variant='outlined'
              placeholder={`${warehousePrice ?? ''}`}
              defaultValue={`${warehousePrice}`}
              {...register(`${priceField}`)}
              error={!!errors[priceField]}
              helperText={errors?.[priceField]?.message as string}
              disabled={
                getValues('volumeBasedDiscount') || match?.isActionRequiredByLsp
              }
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export function ClientServiceRatesSideDrawer({
  warehouse,
  match,
  isOpen,
  isAddingToShortlist,
  isOnShortlist,
  isProposing,
  isAccepting,
  comment,
  setIsOpen,
  handleAddToShortlist,
  handleProposal,
  handleAcceptMatch,
}: {
  warehouse: Warehouse;
  match: Match | null;
  isOpen: boolean;
  isAddingToShortlist: boolean;
  isOnShortlist: boolean;
  isProposing: boolean;
  isAccepting: boolean;
  comment: any;
  setIsOpen: (value: boolean) => void;
  handleAddToShortlist: () => void;
  handleProposal: (data: ProposalFormValues) => void;
  handleAcceptMatch: () => void;
}) {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const [initialValues, setInitialValues] = useState<{ [key: string]: any }>(
    {}
  );

  const currencies = SUPPORTED_CURRENCY_GROUPS.flatMap(
    (currencyGroup) => currencyGroup.currencies
  );

  let currencySymbol: string | undefined;

  if (warehouse.currency) {
    currencySymbol = currencies.find(
      (currency) => currency.code === warehouse.currency
    )?.symbol;
  }

  const validationSchema = Yup.object().shape({
    storagePricePerWeek: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.storagePricePerWeek === null &&
          !match?.serviceRates?.storagePricePerWeek?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    // this is required even though it has no field
    palletReceiptAndPutAwayPrice: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.palletReceiptAndPutAwayPrice === null &&
          !match?.serviceRates?.palletReceiptAndPutAwayPrice
            ?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    palletPickingAndDespatchPrice: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.palletPickingAndDespatchPrice === null &&
          !match?.serviceRates?.palletPickingAndDespatchPrice
            ?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    casePickingAndDespatchPrice: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.casePickingAndDespatchPrice === null &&
          !match?.serviceRates?.casePickingAndDespatchPrice?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    itemPickingAndDespatchPrice: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.itemPickingAndDespatchPrice === null &&
          !match?.serviceRates?.itemPickingAndDespatchPrice?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    containerHandlingPrice: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.containerHandlingPrice === null &&
          !match?.serviceRates?.containerHandlingPrice?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    demurragePricePerDay: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.demurragePricePerDay === null &&
          !match?.serviceRates?.demurragePricePerDay?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    palletReturnsPrice: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.palletReturnsPrice === null &&
          !match?.serviceRates?.palletReturnsPrice?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    itemReturnsPrice: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.itemReturnsPrice === null &&
          !match?.serviceRates?.itemReturnsPrice?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    caseReturnsPrice: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.caseReturnsPrice === null &&
          !match?.serviceRates?.caseReturnsPrice?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    auxiliaryServicePricePerManHour: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.auxiliaryServicePricePerManHour === null &&
          !match?.serviceRates?.auxiliaryServicePricePerManHour
            ?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    additionalSurchargesPercentage: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true ||
        (match?.originalRates?.additionalSurchargesPercentage === null &&
          !match?.serviceRates?.additionalSurchargesPercentage
            ?.lspProposedValue &&
          !match?.isActionRequiredByLsp)
          ? $schema.nullable()
          : $schema.required('This field is required')
    ),
    volumeBasedDiscount: Yup.boolean(),
    monthlyTurnoverOne: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true
          ? $schema
              .required('This field is required')
              .notOneOf(
                [
                  Yup.ref('monthlyTurnoverTwo'),
                  Yup.ref('monthlyTurnoverThree'),
                ],
                'This value must be unique'
              )
          : $schema
    ),
    monthlyDiscountOne: Yup.string().when(
      'volumeBasedDiscount',
      ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true
          ? $schema.required('This field is required')
          : $schema
    ),
    monthlyTurnoverTwo: Yup.string()
      .nullable()
      .when('volumeBasedDiscount', ([volumeBasedDiscount], $schema) =>
        volumeBasedDiscount === true
          ? $schema
              .required('This field is required')
              .notOneOf(
                [
                  Yup.ref('monthlyTurnoverOne'),
                  Yup.ref('monthlyTurnoverThree'),
                ],
                'This value must be unique'
              )
          : $schema
      ),
    monthlyDiscountTwo: Yup.string().nullable(),
    // monthlyTurnoverThree: Yup.string()
    //   .nullable()
    //   .when('volumeBasedDiscount', ([volumeBasedDiscount], $schema) =>
    //     volumeBasedDiscount === true
    //       ? $schema
    //           .required('This field is required')
    //           .notOneOf(
    //             [Yup.ref('monthlyTurnoverTwo'), Yup.ref('monthlyTurnoverOne')],
    //             'This value must be unique'
    //           )
    //       : $schema
    //   ),
    monthlyDiscountThree: Yup.string().nullable(),
    billingCycleFrequency: Yup.string().required('Billing cycle is required'),
    paymentTermsInDays: Yup.number().required('Contract terms are required'),
    desiredStartDate: Yup.string().required(
      'A desired start date is required.'
    ),
    averageStorageQuantityPerWeek: Yup.number()
      .required('Average storage quantity per week is required.')
      .typeError('Average storage quantity per week must be a number')
      .moreThan(0, 'Average storage quantity per week must be greater than 0'),
    estimatedDurationInMonths: Yup.number()
      .required('An estimated duration is required.')
      .typeError('Estimated duration must be a number')
      .moreThan(0, 'Estimated duration must be greater than 0'),
    averageNumberOfInboundShipments: Yup.number()
      .required('Average number of inbound shipments is required.')
      .typeError('Average number of inbound shipments must be a number')
      .moreThan(
        0,
        'Average number of inbound shipments must be greater than 0'
      ),
    averageNumberOfOutboundShipments: Yup.number()
      .required('Average number of outbound shipments is required.')
      .typeError('Average number of outbound shipments must be a number')
      .moreThan(
        0,
        'Average number of outbound shipments must be greater than 0'
      ),
    comment: Yup.string().nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
    watch,
  } = useForm<ProposalFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      storagePricePerWeek: undefined,
      palletReceiptAndPutAwayPrice: undefined,
      palletPickingAndDespatchPrice: undefined,
      casePickingAndDespatchPrice: undefined,
      itemPickingAndDespatchPrice: undefined,
      containerHandlingPrice: undefined,
      demurragePricePerDay: undefined,
      palletReturnsPrice: undefined,
      itemReturnsPrice: undefined,
      caseReturnsPrice: undefined,
      auxiliaryServicePricePerManHour: undefined,
      additionalSurchargesPercentage: undefined,
      volumeBasedDiscount: false,
      monthlyTurnoverOne: undefined,
      monthlyDiscountOne: undefined,
      monthlyTurnoverTwo: undefined,
      monthlyDiscountTwo: undefined,
      monthlyTurnoverThree: undefined,
      monthlyDiscountThree: undefined,
      billingCycleFrequency: 'Monthly',
      paymentTermsInDays: 30,
      desiredStartDate: dayjs(new Date()).format(),
      averageStorageQuantityPerWeek: undefined,
      estimatedDurationInMonths: undefined,
      averageNumberOfInboundShipments: undefined,
      averageNumberOfOutboundShipments: undefined,
      comment: '',
    },
  });

  const onSubmit = (
    data: ProposalFormValues,
    handler: (data: ProposalFormValues) => void
  ) => {
    console.log(errors, 'errors');
    handler(data);
  };

  useEffect(() => {
    clearErrors();
  }, [getValues('volumeBasedDiscount')]);

  useEffect(() => {
    if (match) {
      const values = {
        storagePricePerWeek:
          match.serviceRates?.storagePricePerWeek.shipperProposedValue ??
          match.originalRates.storagePricePerWeek,
        palletReceiptAndPutAwayPrice:
          match.serviceRates?.palletReceiptAndPutAwayPrice
            .shipperProposedValue ??
          match.originalRates.palletReceiptAndPutAwayPrice,
        palletPickingAndDespatchPrice:
          match.serviceRates?.palletPickingAndDespatchPrice
            .shipperProposedValue ??
          match.originalRates.palletPickingAndDespatchPrice,
        casePickingAndDespatchPrice:
          match.serviceRates?.casePickingAndDespatchPrice
            .shipperProposedValue ??
          match.originalRates.casePickingAndDespatchPrice,
        itemPickingAndDespatchPrice:
          match.serviceRates?.itemPickingAndDespatchPrice
            .shipperProposedValue ??
          match.originalRates.itemPickingAndDespatchPrice,
        containerHandlingPrice:
          match.serviceRates?.containerHandlingPrice.shipperProposedValue ??
          match.originalRates.containerHandlingPrice,
        demurragePricePerDay:
          match.serviceRates?.demurragePricePerDay.shipperProposedValue ??
          match.originalRates.demurragePricePerDay,
        palletReturnsPrice:
          match.serviceRates?.palletReturnsPrice.shipperProposedValue ??
          match.originalRates.palletReturnsPrice,
        itemReturnsPrice:
          match.serviceRates?.itemReturnsPrice.shipperProposedValue ??
          match.originalRates.itemReturnsPrice,
        caseReturnsPrice:
          match.serviceRates?.caseReturnsPrice.shipperProposedValue ??
          match.originalRates.caseReturnsPrice,
        auxiliaryServicePricePerManHour:
          match.serviceRates?.auxiliaryServicePricePerManHour
            .shipperProposedValue ??
          match.originalRates.auxiliaryServicePricePerManHour,
        additionalSurchargesPercentage:
          match.serviceRates?.additionalSurchargesPercentage
            .shipperProposedValue ??
          match.originalRates.additionalSurchargesPercentage,
        volumeBasedDiscount: match.volumeDiscounts?.length > 0,
        monthlyTurnoverOne: match.volumeDiscounts?.[0]?.maxTotalMonthlyTurnover,
        monthlyDiscountOne: match.isActionRequiredByLsp
          ? match.volumeDiscounts?.[0]?.shipperProposedDiscountPercentage
          : match.volumeDiscounts?.[0]?.lspProposedDiscountPercentage,
        monthlyTurnoverTwo: match.volumeDiscounts?.[1]?.maxTotalMonthlyTurnover,
        monthlyDiscountTwo: match.isActionRequiredByLsp
          ? match.volumeDiscounts?.[1]?.shipperProposedDiscountPercentage
          : match.volumeDiscounts?.[1]?.lspProposedDiscountPercentage,
        monthlyTurnoverThree:
          match.volumeDiscounts?.[2]?.maxTotalMonthlyTurnover,
        monthlyDiscountThree: match.isActionRequiredByLsp
          ? match.volumeDiscounts?.[2]?.shipperProposedDiscountPercentage
          : match.volumeDiscounts?.[2]?.lspProposedDiscountPercentage,
        billingCycleFrequency: !match.billingCycleFrequency
          ? 'Monthly'
          : match.billingCycleFrequency,
        paymentTermsInDays:
          match.paymentTermsInDays === 0 ? 30 : match.paymentTermsInDays,
        desiredStartDate:
          match.desiredStartDate === null
            ? dayjs().format()
            : dayjs(match.desiredStartDate).format(),
        estimatedDurationInMonths: match.estimatedDurationInMonths,
        averageStorageQuantityPerWeek: match.averageStorageQuantityPerWeek,
        averageNumberOfInboundShipments:
          match.averageNumberOfInboundShipmentsPerWeek,
        averageNumberOfOutboundShipments:
          match.averageNumberOfOutboundShipmentsPerWeek,
      };
      setInitialValues(values);
      reset(values);
    }
  }, [match]);

  const formValues = watch();

  const hasFormChanged = () => {
    return Object.keys(initialValues).some((key) => {
      if (
        key === 'desiredStartDate' ||
        key === 'comment' ||
        key === 'averageNumberOfInboundShipments' ||
        key === 'averageNumberOfOutboundShipments' ||
        key === 'averageStorageQuantityPerWeek' ||
        key === 'estimatedDurationInMonths' ||
        key === 'monthlyDiscountOne' ||
        key === 'monthlyDiscountTwo' ||
        key === 'monthlyDiscountThree' ||
        key === 'monthlyTurnoverOne' ||
        key === 'monthlyTurnoverTwo' ||
        key === 'monthlyTurnoverThree' ||
        key === 'billingCycleFrequency' ||
        key === 'paymentTermsInDays'
      ) {
        return false;
      }
      // eslint-disable-next-line
      return initialValues[key] != formValues[key];
    });
  };

  return (
    <Drawer
      hideBackdrop
      anchor={isDesktop ? 'right' : 'bottom'}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      sx={{
        '&.MuiDrawer-root': {
          left: 'unset',
        },
        '.MuiDrawer-paper': {
          backgroundColor: 'var(--colors-gray2)',
          color: 'var(--colors-gray12)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          // eslint-disable-next-line no-nested-ternary
          width: isDesktop ? (isOnShortlist ? '53rem' : '32rem') : '100%',
          p: 16,
          textAlign: isDesktop ? 'right' : 'left',
        }}
      >
        <Stack direction='row' width='100%' justifyContent='space-between'>
          <IconButton
            aria-label='close'
            onClick={() => setIsOpen(false)}
            size='medium'
            sx={{
              height: '2.5rem',
              color: (theme) => theme.palette.grey[500],
              mb: '1rem',
              ...(!isDesktop && {
                alignSelf: 'flex-end',
              }),
            }}
          >
            <CloseIcon />
          </IconButton>
          {isOnShortlist && (
            <Typography fontSize='1rem'>
              You can either accept the Standard Service Rates or propose
              alternatives, including any volume discounts and commitments below
            </Typography>
          )}
        </Stack>
        <Box sx={{ p: 8, width: '100%' }}>
          <form id='proposalForm'>
            <Stack gap={24}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 8,
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '1rem',
                  }}
                >
                  <Box sx={{ flex: '1 1 0%', marginRight: '1rem' }}>
                    <Typography variant='h6'>
                      {match?.matchStatus === 'Reviewing T&Cs' ||
                      match?.matchStatus === 'Contracted'
                        ? 'Agreed Service Rates'
                        : 'Service Rates'}
                    </Typography>
                  </Box>
                  {isOnShortlist && (
                    <Box sx={{ flex: '1 1 0%' }}>
                      <Typography variant='h6'>Your Proposal</Typography>
                    </Box>
                  )}
                </Box>
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='storagePricePerWeek'
                  label={`Storage (per
                  ${
                    warehouse.storageCapacityUnitOfMeasure === 'Pallets'
                      ? 'pallet-week'
                      : warehouse.storageCapacityUnitOfMeasure.toLowerCase()
                  })`}
                  warehousePrice={warehouse.storageCapacityUnitOfMeasure === 'Pallets' ? warehouse.palletStoragePricePerWeek : warehouse.storageCapacityUnitOfMeasure === 'Square Metre' ? warehouse.squareMetreStoragePricePerWeek : warehouse.squareFeetStoragePricePerWeek}
                  shipperProposedValue={
                    match?.serviceRates?.storagePricePerWeek
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.storagePricePerWeek.lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.storagePricePerWeek.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='palletReceiptAndPutAwayPrice'
                  label='Receipt and putaway (per pallet)'
                  warehousePrice={
                    warehouse.palletReceiptAndPutAwayPrice
                  }
                  shipperProposedValue={
                    match?.serviceRates?.palletReceiptAndPutAwayPrice
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.palletReceiptAndPutAwayPrice
                      .lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.palletReceiptAndPutAwayPrice
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='palletPickingAndDespatchPrice'
                  label='Pallet picking and despatch (per pallet)'
                  warehousePrice={
                    warehouse.palletPickingAndDespatchPrice
                  }
                  shipperProposedValue={
                    match?.serviceRates?.palletPickingAndDespatchPrice
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.palletPickingAndDespatchPrice
                      .lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.palletPickingAndDespatchPrice
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='casePickingAndDespatchPrice'
                  label='Case picking and despatch (per case)'
                  warehousePrice={
                    warehouse.casePickingAndDespatchPrice
                  }
                  shipperProposedValue={
                    match?.serviceRates?.casePickingAndDespatchPrice
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.casePickingAndDespatchPrice
                      .lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.casePickingAndDespatchPrice
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='itemPickingAndDespatchPrice'
                  label='Item picking and despatch (per item)'
                  warehousePrice={
                    warehouse.itemPickingAndDespatchPrice
                  }
                  shipperProposedValue={
                    match?.serviceRates?.itemPickingAndDespatchPrice
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.itemPickingAndDespatchPrice
                      .lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.itemPickingAndDespatchPrice
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='containerHandlingPrice'
                  label='Container handling (per container)'
                  warehousePrice={warehouse.containerHandlingPrice}
                  shipperProposedValue={
                    match?.serviceRates?.containerHandlingPrice
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.containerHandlingPrice.lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.containerHandlingPrice.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='demurragePricePerDay'
                  label='Demurrage (per consignment day)'
                  warehousePrice={warehouse.demurragePricePerDay}
                  shipperProposedValue={
                    match?.serviceRates?.demurragePricePerDay
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.demurragePricePerDay.lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.demurragePricePerDay.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='palletReturnsPrice'
                  label={`Returns (per
                  ${
                    warehouse.storageCapacityUnitOfMeasure === 'Pallets'
                      ? 'pallet'
                      : warehouse.storageCapacityUnitOfMeasure.toLowerCase()
                  })`}
                  warehousePrice={warehouse.palletReturnsPrice}
                  shipperProposedValue={
                    match?.serviceRates?.palletReturnsPrice.shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.palletReturnsPrice.lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.palletReturnsPrice.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='itemReturnsPrice'
                  label='Returns (per item)'
                  warehousePrice={warehouse.itemReturnsPrice}
                  shipperProposedValue={
                    match?.serviceRates?.itemReturnsPrice.shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.itemReturnsPrice.lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.itemReturnsPrice.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='caseReturnsPrice'
                  label='Returns (per case)'
                  warehousePrice={warehouse.caseReturnsPrice}
                  shipperProposedValue={
                    match?.serviceRates?.caseReturnsPrice.shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.caseReturnsPrice.lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.caseReturnsPrice.acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='auxiliaryServicePricePerManHour'
                  label='Auxiliary services(per man-hour)'
                  warehousePrice={
                    warehouse.auxiliaryServicePricePerManHour
                  }
                  shipperProposedValue={
                    match?.serviceRates?.auxiliaryServicePricePerManHour
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.auxiliaryServicePricePerManHour
                      .lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.auxiliaryServicePricePerManHour
                      .acceptedValue
                  }
                />
                <PricingDisplay
                  isDesktop={isDesktop}
                  register={register}
                  getValues={getValues}
                  watch={watch}
                  setValue={setValue}
                  match={match}
                  isOnShortlist={isOnShortlist}
                  currencySymbol={currencySymbol}
                  errors={errors}
                  priceField='additionalSurchargesPercentage'
                  label='Surcharges (additional %-ge to the base)'
                  warehousePrice={
                    warehouse.additionalSurchargesPercentage
                  }
                  shipperProposedValue={
                    match?.serviceRates?.additionalSurchargesPercentage
                      .shipperProposedValue
                  }
                  proposedValue={
                    match?.serviceRates?.additionalSurchargesPercentage
                      .lspProposedValue
                  }
                  acceptedValue={
                    match?.serviceRates?.additionalSurchargesPercentage
                      .acceptedValue
                  }
                  percentage
                />
              </Box>
              {!isOnShortlist && (
                <div>
                  All prices listed above are exclusive of VAT and FLOX
                  transaction fees.
                </div>
              )}
              {isOnShortlist && (
                <>
                  <div>
                    <p className='mb-4'>Disclaimer:</p>
                    <p className='mb-4'>
                      All prices listed above are exclusive of VAT and FLOX
                      transaction fees. FLOX transaction fees for the services
                      are as follows:
                    </p>
                    <ul>
                      <li>Storage: 0%</li>
                      <li>Handling: 0%</li>
                      <li>Other Services: 0%</li>
                      <li>Haulage (FTL/LTL): 0%</li>
                      <li>Urban Distribution: 0%</li>
                      <li>
                        Additional financial transaction fees vary between 0%
                        and 1.75%, depending on the selected payment option.
                      </li>
                    </ul>
                  </div>
                  <InputGroup
                    direction='row'
                    css={{
                      gap: '$space16',
                      padding: '$space2',
                      justifyContent: isDesktop ? 'flex-end' : 'left',
                    }}
                  >
                    <Controller
                      name='volumeBasedDiscount'
                      control={control}
                      render={({ field }) => {
                        return (
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={field.value}
                                  onChange={(
                                    event: ChangeEvent<HTMLInputElement>,
                                    checked: boolean
                                  ) => {
                                    setValue('volumeBasedDiscount', checked, {
                                      shouldValidate: true,
                                    });
                                  }}
                                  name={field.name}
                                  disabled={match?.isActionRequiredByLsp}
                                />
                              }
                              label='Apply volume-based discount structure instead'
                            />
                          </FormGroup>
                        );
                      }}
                    />
                  </InputGroup>
                  {getValues().volumeBasedDiscount && (
                    <Stack direction='column' gap={12} alignItems='end'>
                      <Stack direction='row' gap={48}>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>
                            Total monthly turnover up to:
                          </Typography>
                          <TextField
                            variant='outlined'
                            placeholder={`${currencySymbol}#,000.00`}
                            {...register('monthlyTurnoverOne')}
                            error={!!errors.monthlyTurnoverOne}
                            helperText={
                              errors?.monthlyTurnoverOne?.message as string
                            }
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByLsp
                            }
                          />
                        </Stack>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>Discount:</Typography>
                          <TextField
                            variant='outlined'
                            placeholder='#.#%'
                            {...register('monthlyDiscountOne')}
                            error={!!errors.monthlyDiscountOne}
                            helperText={
                              errors?.monthlyDiscountOne?.message as string
                            }
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByLsp
                            }
                          />
                        </Stack>
                      </Stack>
                      <Stack direction='row' gap={48}>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>
                            Between £{getValues().monthlyTurnoverOne} and:
                          </Typography>
                          <TextField
                            variant='outlined'
                            placeholder={`${currencySymbol}#0,000.00`}
                            {...register('monthlyTurnoverTwo')}
                            error={!!errors.monthlyTurnoverTwo}
                            helperText={
                              errors?.monthlyTurnoverTwo?.message as string
                            }
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByLsp
                            }
                          />
                        </Stack>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>Discount:</Typography>
                          <TextField
                            variant='outlined'
                            placeholder='#.#%'
                            {...register('monthlyDiscountTwo')}
                            // error={!!errors.monthlyDiscountTwo}
                            // helperText={
                            //   errors?.monthlyDiscountTwo?.message as string
                            // }
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByLsp
                            }
                          />
                        </Stack>
                      </Stack>
                      <Stack direction='row' gap={48}>
                        <Stack
                          direction='row'
                          gap={12}
                          alignItems='center'
                          sx={{ marginRight: '9em' }}
                        >
                          <Typography variant='body1'>above:</Typography>
                          <Typography variant='body1'>
                            £{getValues().monthlyTurnoverTwo}
                          </Typography>
                        </Stack>
                        <Stack direction='row' gap={12} alignItems='center'>
                          <Typography variant='body1'>Discount:</Typography>
                          <TextField
                            variant='outlined'
                            placeholder='#.#%'
                            {...register('monthlyDiscountThree')}
                            // error={!!errors.monthlyDiscountThree}
                            // helperText={
                            //   errors?.monthlyDiscountThree?.message as string
                            // }
                            disabled={
                              !getValues('volumeBasedDiscount') ||
                              match?.isActionRequiredByLsp
                            }
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  )}

                  <Stack direction='column' gap={12} alignItems='end'>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Billing Cycle (days)
                      </Typography>
                      <Select {...register('billingCycleFrequency')}>
                        <option value='Weekly'>Weekly</option>
                        <option value='Every Two Weeks'>Every Two Weeks</option>
                        <option value='Monthly'>Monthly</option>
                      </Select>
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Payment Terms (days)
                      </Typography>
                      <Select {...register('paymentTermsInDays')}>
                        <option value={7}>7</option>
                        <option value={14}>14</option>
                        <option value={30}>30</option>
                      </Select>
                    </Stack>
                  </Stack>
                  <Stack direction='column' gap={12} alignItems='end'>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Desired Start Date:
                      </Typography>
                      <Controller
                        name='desiredStartDate'
                        control={control}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <DatePicker
                              format='DD/MM/YYYY'
                              minDate={dayjs(new Date())}
                              inputRef={field.ref}
                              onChange={(value) => {
                                setValue(
                                  'desiredStartDate',
                                  dayjs(value).format(),
                                  {
                                    shouldValidate: true,
                                  }
                                );
                              }}
                              defaultValue={
                                match?.desiredStartDate &&
                                match?.desiredStartDate !== null
                                  ? dayjs(match.desiredStartDate)
                                  : dayjs()
                              }
                              disabled={match?.isActionRequiredByLsp}
                              slotProps={{
                                textField: {
                                  onBlur: field.onBlur,
                                  name: field.name,
                                  multiline: !isDesktop,
                                  ...(!isDesktop && {
                                    InputLabelProps: {
                                      shrink: true,
                                    },
                                  }),
                                  error: Boolean(error),
                                  helperText: error?.message,
                                },
                              }}
                            />
                          );
                        }}
                      />
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Storage needed (in{' '}
                        {warehouse.storageCapacityUnitOfMeasure.toLowerCase()},
                        per week) *:
                      </Typography>
                      <TextField
                        variant='outlined'
                        placeholder='12'
                        {...register('averageStorageQuantityPerWeek')}
                        error={!!errors.averageStorageQuantityPerWeek}
                        helperText={
                          errors?.averageStorageQuantityPerWeek
                            ?.message as string
                        }
                        disabled={match?.isActionRequiredByLsp}
                      />
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Estimated duration in months *:
                      </Typography>
                      <TextField
                        variant='outlined'
                        placeholder='12'
                        {...register('estimatedDurationInMonths')}
                        error={!!errors.estimatedDurationInMonths}
                        helperText={
                          errors?.estimatedDurationInMonths?.message as string
                        }
                        disabled={match?.isActionRequiredByLsp}
                      />
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Average number of inbound shipments (per week) *:
                      </Typography>
                      <TextField
                        variant='outlined'
                        placeholder='100'
                        {...register('averageNumberOfInboundShipments')}
                        error={!!errors.averageNumberOfInboundShipments}
                        helperText={
                          errors?.averageNumberOfInboundShipments
                            ?.message as string
                        }
                        disabled={match?.isActionRequiredByLsp}
                      />
                    </Stack>
                    <Stack direction='row' gap={12} alignItems='center'>
                      <Typography variant='body1'>
                        Average number of outbound shipments (per week) *:
                      </Typography>
                      <TextField
                        variant='outlined'
                        placeholder='100'
                        {...register('averageNumberOfOutboundShipments')}
                        error={!!errors.averageNumberOfOutboundShipments}
                        helperText={
                          errors?.averageNumberOfOutboundShipments
                            ?.message as string
                        }
                        disabled={match?.isActionRequiredByLsp}
                      />
                    </Stack>
                    <Stack direction='column' gap={12} width='100%' mt={12}>
                      {/* <Typography variant='body1'>
                        {match?.comments &&
                          match?.comments?.length > 0 &&
                          `${match?.comments.pop()?.createdByUserName}: ${
                            match?.comments.pop()?.commentText
                          }`}
                      </Typography> */}
                      <Typography variant='body1'>
                        {comment &&
                          `${comment.createdByUserName}: ${comment.commentText}`}
                      </Typography>
                      <TextField
                        variant='outlined'
                        placeholder='Comments'
                        multiline
                        minRows={3}
                        sx={{ width: '100%' }}
                        {...register('comment')}
                        error={!!errors.comment}
                        helperText={errors?.comment?.message as string}
                        disabled={match?.isActionRequiredByLsp}
                      />
                    </Stack>
                  </Stack>
                </>
              )}
              {isOnShortlist && (
                <Typography variant='body1'>
                  *) Surcharges may be applied for Rush Order/Expedited Service,
                  Peak Season, Delay/Detention, Cancellation, Late Payment, and
                  other extraordinary service requests.
                </Typography>
              )}
              {isOnShortlist ? (
                <Stack
                  direction='row'
                  gap={12}
                  justifyContent={isDesktop ? 'flex-end' : 'left'}
                >
                  <Button
                    variant='contained'
                    size='large'
                    sx={{ textTransform: 'none' }}
                    onClick={handleSubmit((data) =>
                      onSubmit(data, handleProposal)
                    )}
                    disabled={
                      isProposing || isAccepting || match?.isActionRequiredByLsp
                    }
                  >
                    {isProposing
                      ? 'Proposing...'
                      : match?.isActionRequiredByLsp
                      ? 'Proposed'
                      : 'Send Proposal'}
                  </Button>
                  <Button
                    variant='contained'
                    size='large'
                    sx={{ textTransform: 'none' }}
                    onClick={handleAcceptMatch}
                    disabled={
                      isProposing ||
                      isAccepting ||
                      match?.isActionRequiredByLsp ||
                      hasFormChanged()
                    }
                  >
                    {isAccepting ? 'Accepting...' : 'Accept Rates'}
                  </Button>
                </Stack>
              ) : match?.matchStatus === 'Reviewing T&Cs' ||
                match?.matchStatus === 'Contracted' ? (
                <div>
                  <LoadingButton
                    size='large'
                    variant='contained'
                    sx={{ textTransform: 'none' }}
                    disabled={match?.matchStatus === 'Reviewing T&Cs'}
                    onClick={() =>
                      window.open(match?.legalDocument.url, '_blank')
                    }
                  >
                    {match?.matchStatus === 'Reviewing T&Cs'
                      ? 'Waiting for other party to sign'
                      : 'View contract'}
                  </LoadingButton>
                </div>
              ) : (
                <div>
                  <LoadingButton
                    size='large'
                    variant='contained'
                    loading={isAddingToShortlist}
                    loadingIndicator='Adding...'
                    disabled={isAddingToShortlist}
                    sx={{ textTransform: 'none' }}
                    onClick={handleAddToShortlist}
                  >
                    Add to Shortlist
                  </LoadingButton>
                </div>
              )}
              {isOnShortlist && <Typography>* is required</Typography>}
            </Stack>
          </form>
        </Box>
      </Box>
    </Drawer>
  );
}
