import { View } from '@views/View';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import { BillingCycle } from './invoiceTypes';
import { useAuthHttp } from '../../hooks';
import { INVOICE_LIST } from '../../constants';
import { useAuth } from '../../context';
import { UserRoleService } from '../../services';
import { LSPInvoice } from './LSPInvoice';
import { ShipperInvoice } from './ShipperInvoice';

export function InvoiceDetails() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const { user } = useAuth();
  const { isWarehouseClient } = UserRoleService();
  const authHttp = useAuthHttp();
  const { id } = useParams();
  const [billingCycle, setBillingCycle] = useState<BillingCycle>();
  const [isLoading, setIsLoading] = useState(false);

  const fetchInvoice = async () => {
    setIsLoading(true);
    try {
      const { data } = await authHttp.post(
        INVOICE_LIST,
        {
          ids: [id],
          statuses: isWarehouseClient(user) ? ['Finalised', 'Paid'] : '',
        },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        setBillingCycle(data.billingCycles[0]);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoice();
  }, []);

  if (isLoading) {
    return (
      <View>
        <div>Loading...</div>
      </View>
    );
  }

  if (!billingCycle) {
    return (
      <View>
        <div>No invoice found</div>
      </View>
    );
  }

  return isWarehouseClient(user) ? (
    <ShipperInvoice billingCycle={billingCycle} />
  ) : (
    <LSPInvoice billingCycle={billingCycle} />
  );
}
