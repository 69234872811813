/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from '@components/Button';
import { MenuItem, Select } from '@mui/material';
import { Match } from '@views/WarehouseDetails/WarehouseDetails';
import { classNames } from '../../utils/classes';
import {
  additionalServicesOptions,
  BillingCycle,
  InvoiceAdditionalService,
  ServiceRates,
  VAT_RATE,
} from './invoiceTypes';

export type InvoiceTableEditSectionProps = {
  handleAdditionalServiceDescriptionChange: (value: string, id: string) => void;
  additionalServices: InvoiceAdditionalService[];
  setAdditionalServices: React.Dispatch<
    React.SetStateAction<InvoiceAdditionalService[]>
  >;
  setOtherAmount: React.Dispatch<React.SetStateAction<number>>;
  otherAmountVat: number;
  handleDeleteService: (id: string) => void;
  manHours: number;
  setManHours: React.Dispatch<React.SetStateAction<number>>;
  auxiliaryServicesPrice: number;
  setAuxiliaryServicesPrice: React.Dispatch<React.SetStateAction<number>>;
};

export function InvoiceTableEditSection({
  billingCycle,
  match,
  ...props
}: {
  billingCycle: BillingCycle;
  match: Match | undefined;
} & InvoiceTableEditSectionProps) {
  return (
    <>
      {!!props.auxiliaryServicesPrice && (
        <tr
          className={classNames(
            'border-gray-200',
            'border-t',
            'hover:bg-gray-50',
            'transition-colors',
            'duration-200',
            'ease-in-out'
          )}
        >
          <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
            {billingCycle.invoice.lines.length + 1}
          </td>
          <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
            <div className='flex items-center justify-between space-x-2'>
              <span>Auxiliary Services</span>
              <div className='flex items-center space-x-2'>
                <span>for</span>
                <label className='hidden' htmlFor='manHours'>
                  Man Hours
                </label>
                <input
                  type='text'
                  id='manHours'
                  name='manHours'
                  className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-floxBlue focus:ring-floxBlue sm:text-sm'
                  placeholder='##.#'
                  onChange={(e) => {
                    props.setManHours(Number(e.target.value));
                  }}
                />
                <span>man-hours</span>
              </div>
            </div>
          </td>
          <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
            {billingCycle.invoice.lines[0].currencySymbol}
            {(props.manHours * props.auxiliaryServicesPrice).toFixed(2)}
          </td>
          <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
            {billingCycle.invoice.lines[0].currencySymbol}
            {(
              (props.manHours * props.auxiliaryServicesPrice * VAT_RATE) /
              100
            ).toFixed(2)}
          </td>
          <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
            <span className='sr-only' />
          </td>
        </tr>
      )}
      {props.additionalServices.length > 0 && (
        <>
          {props.additionalServices.map((service) => {
            return (
              <tr
                key={service.id}
                className={classNames(
                  'border-gray-200',
                  'border-t',
                  'hover:bg-gray-50',
                  'transition-colors',
                  'duration-200',
                  'ease-in-out'
                )}
              >
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                  {service.lineNumber}
                </td>
                <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
                  <div className='flex items-center justify-between space-x-2'>
                    <Select
                      id='additional-service-select'
                      value={service.description}
                      onChange={(e) =>
                        props.handleAdditionalServiceDescriptionChange(
                          e.target.value,
                          service.id
                        )
                      }
                      data-testid='additional-service-select'
                      label='additional-service'
                      size='small'
                      fullWidth
                      variant='outlined'
                      sx={{
                        color: 'var(--colors-gray12)',
                        width: '14.25rem',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: 'var(--colors-gray7)',
                        },
                        '& .MuiSelect-icon': {
                          color: 'var(--colors-gray12)',
                        },
                        '& .MuiList-root': {
                          backgroundColor: 'var(--colors-gray1)',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            bgcolor: 'var(--colors-gray1)',
                          },
                        },
                      }}
                      inputProps={{
                        'data-testid': 'additional-service-select-input',
                      }}
                    >
                      {additionalServicesOptions.map(
                        (additionalServiceOption) => (
                          <MenuItem
                            key={additionalServiceOption.value}
                            value={additionalServiceOption.value}
                            sx={{
                              '&.MuiMenuItem-root': {
                                backgroundColor: 'var(--colors-gray1)',
                                color: 'var(--colors-gray12)',
                              },
                              '&.MuiMenuItem-root:hover': {
                                backgroundColor: 'var(--colors-gray3)',
                              },
                              '&.MuiMenuItem-root.Mui-selected': {
                                backgroundColor: 'var(--colors-gray4)',
                                color: 'var(--colors-gray12)',
                              },
                            }}
                          >
                            {additionalServiceOption.label}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <span>
                      <input
                        type='text'
                        id={`additoinalService${service.id}`}
                        name={`additoinalService${service.id}`}
                        className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-floxBlue focus:ring-floxBlue sm:text-sm'
                        placeholder='##.#'
                        onChange={(e) => {
                          const updatedServices = props.additionalServices.map(
                            (s) => {
                              if (s.id === service.id) {
                                return {
                                  ...s,
                                  amount: Number(e.target.value),
                                };
                              }

                              return s;
                            }
                          );

                          props.setAdditionalServices(updatedServices);
                        }}
                      />
                    </span>
                  </div>
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                  {billingCycle.invoice.lines[0].currencySymbol}
                  {service.amount && service.description && match
                    ? (
                        service.amount *
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        match?.serviceRates[
                          service.description as keyof ServiceRates
                        ]?.acceptedValue
                      ).toFixed(2)
                    : '-'}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                  {billingCycle.invoice.lines[0].currencySymbol}
                  {service.amount && service.description && match
                    ? (
                        (service.amount *
                          // eslint-disable-next-line no-unsafe-optional-chaining
                          match?.serviceRates[
                            service.description as keyof ServiceRates
                          ]?.acceptedValue *
                          VAT_RATE) /
                        100
                      ).toFixed(2)
                    : '-'}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                  <Button
                    variant='primary'
                    size='sm'
                    onClick={() => props.handleDeleteService(service.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </>
      )}
      <tr
        key={billingCycle.invoice.id}
        className={classNames(
          'border-gray-200',
          'border-t',
          'hover:bg-gray-50',
          'transition-colors',
          'duration-200',
          'ease-in-out'
        )}
      >
        <td
          colSpan={4}
          className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 text-center'
        >
          <Button
            variant='primary'
            size='sm'
            onClick={() =>
              props.setAdditionalServices([
                ...props.additionalServices,
                {
                  id: crypto.randomUUID(),
                  lineNumber:
                    billingCycle.invoice.lines.length +
                    props.additionalServices.length +
                    (props.auxiliaryServicesPrice ? 2 : 1),
                  description: 'palletStorage',
                  amount: 0,
                },
              ])
            }
          >
            Add additional service
          </Button>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
          <span className='sr-only' />
        </td>
      </tr>
      <tr
        className={classNames(
          'border-gray-200',
          'border-t',
          'hover:bg-gray-50',
          'transition-colors',
          'duration-200',
          'ease-in-out'
        )}
      >
        <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
          {billingCycle.invoice.lines.length +
            props.additionalServices.length +
            (props.auxiliaryServicesPrice ? 2 : 1)}
        </td>
        <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
          <div className='flex items-center space-x-2'>
            <span>Other:</span>
            <div className='flex items-center space-x-2'>
              <input
                type='text'
                id='otherDescription'
                name='otherDescription'
                className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-floxBlue focus:ring-floxBlue sm:text-sm'
                placeholder='add description'
              />
            </div>
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
          <div className='flex items-center space-x-2'>
            <div>{billingCycle.invoice.lines[0].currencySymbol}</div>
            <label className='hidden' htmlFor='otherAmount'>
              Other Amount
            </label>
            <input
              type='text'
              id='otherAmount'
              name='otherAmount'
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-floxBlue focus:ring-floxBlue sm:text-sm max-w-32'
              placeholder='£##.##'
              onChange={(e) => {
                props.setOtherAmount(Number(e.target.value));
              }}
            />
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
          {props.otherAmountVat.toFixed(2)}
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
          <span className='sr-only' />
        </td>
      </tr>
    </>
  );
}
