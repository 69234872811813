import { Divider } from '@mui/material';
import { Payment } from '@views/Invoice/invoiceTypes';
import moment from 'moment';

export function TransactionSummary({ payment }: { payment: Payment }) {
  return (
    <div
      className='mt-8 p-8 bg-white rounded-lg sm:p-6 
        shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: '50rem',
      }}
    >
      <div className='font-bold mb-4'>Transaction Summary</div>
      {false && <div>Transaction ID: </div>}
      {/* todo */}
      <div className='mb-4'>
        <div className='font-bold'>Reference:</div> {payment.paymentReference}
      </div>
      <div className='mb-4'>
        <div className='font-bold'>Transaction Date:</div>{' '}
        {moment(payment.createdAt).format('YYYY/MM/DD')}
      </div>
      {/* <div>Status: {payment.status}</div> TODO: Add this once we've implemented Yapily web socket */}
      <Divider style={{ marginTop: '16px' }} />
      <div
        className='mt-6'
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          className='font-bold'
          style={{ minWidth: '5rem', textAlign: 'right' }}
        >
          Total:
        </div>
        <div className='font-bold'>
          {payment.currencySymbol}
          {payment.amount.toFixed(2)}
        </div>
      </div>
    </div>
  );
}
