import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '@components/Heading';
import { Stack } from '@mui/material';
import { InfiniteScrollLoading } from '@components/InfiniteScroll';
import { BillingCycle, VAT_RATE } from '@views/Invoice/invoiceTypes';
import { View } from '@views/View';
import { useMediaQuery } from 'usehooks-ts';
import {
  getBillingCycleCost,
  getBillingCycleVatCost,
} from '@views/Invoice/invoiceHelper';
import { classNames } from '../../utils/classes';
import { INVOICE_LIST } from '../../constants';
import { useAuthHttp } from '../../hooks';

export function Payments() {
  const isDesktop = useMediaQuery('(min-width: 62em)');
  const authHttp = useAuthHttp();
  const navigate = useNavigate();
  const [billingCycles, setBillingCycles] = useState<BillingCycle[]>([]);
  const [isInvoicesLoading, setIsInvoicesLoading] = useState(false);

  const invoiceSubtotals = billingCycles.map((billingCycle) => {
    const subTotalAmount = getBillingCycleCost(billingCycle);
    const subTotalVatAmount = getBillingCycleVatCost(billingCycle);

    return {
      invoiceNumber: billingCycle.invoice.invoiceNumber,
      subTotalAmount,
      subTotalVatAmount,
    };
  });

  const fetchInvoices = async () => {
    setIsInvoicesLoading(true);
    try {
      const { data } = await authHttp.post(
        INVOICE_LIST,
        {},
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (data) {
        setBillingCycles(data.billingCycles);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsInvoicesLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, []);

  return (
    <View>
      <div className='flex flex-col h-[90vh]'>
        <Heading as='h1' size={isDesktop ? 'sm' : 'xs'}>
          Receivables
        </Heading>
        <div className='mt-8'>
          FLOX PAYMENT: Transaction details for the services provided between{' '}
          <span className='font-bold'>
            {moment().subtract(2, 'weeks').format('DD/MM/YY')}
          </span>{' '}
          and <span className='font-bold'>{moment().format('DD/MM/YY')}</span>
        </div>
        <div className='mt-8 overflow-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-white sticky top-0'>
              <tr>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3'
                >
                  #
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Invoice details
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Amount
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  VAT @{VAT_RATE}%
                </th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {isInvoicesLoading && (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <InfiniteScrollLoading
                      key='payment-loader'
                      loadingMessage='Loading payments made...'
                      sx={{ gridColumn: '1 / -1' }}
                    />
                  </td>
                </tr>
              )}
              {billingCycles.length === 0 && !isInvoicesLoading && (
                <tr>
                  <td colSpan={12} className='text-center py-4'>
                    <Stack
                      direction='column'
                      alignItems='center'
                      spacing={2}
                      sx={{ gridColumn: '1 / -1' }}
                    >
                      <span className='text-gray-900 text-md font-semibold'>
                        No payments found.
                      </span>
                    </Stack>
                  </td>
                </tr>
              )}
              {billingCycles.map((billingCycle, index) => {
                const subTotalAmount = getBillingCycleCost(billingCycle);
                const subTotalVatAmount = getBillingCycleVatCost(billingCycle);

                return (
                  <tr
                    key={billingCycle.invoice.id}
                    className={classNames(
                      'border-gray-200',
                      'border-t',
                      'hover:bg-gray-50',
                      'transition-colors',
                      'duration-200',
                      'ease-in-out',
                      'cursor-pointer'
                    )}
                    onClick={() => {
                      navigate(`/invoice/${billingCycle.id}`);
                    }}
                  >
                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                      {index + 1}
                    </td>
                    <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
                      <div className='flex items-center justify-between space-x-2'>
                        <span>
                          Services to {billingCycle.shipperBusinessName}
                        </span>
                        <span>
                          invoice - {billingCycle.invoice.invoiceNumber}
                        </span>
                      </div>
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                      {billingCycle.invoice.lines[0].currencySymbol}
                      {subTotalAmount.toFixed(2)}
                    </td>
                    <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                      {billingCycle.invoice.lines[0].currencySymbol}
                      {subTotalVatAmount.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
              <tr
                className={classNames(
                  'border-gray-200',
                  'border-t',
                  'hover:bg-gray-50',
                  'transition-colors',
                  'duration-200',
                  'ease-in-out'
                )}
              >
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                  <span className='sr-only'>Line Number</span>
                </td>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 text-right'>
                  Sub Total:
                </td>
                <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
                  {billingCycles[0]?.invoice.lines[0].currencySymbol}
                  {invoiceSubtotals
                    .map((subtotal) => subtotal.subTotalAmount)
                    .reduce((sum, amount) => sum + amount, 0)
                    .toFixed(2)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900'>
                  {billingCycles[0]?.invoice.lines[0].currencySymbol}
                  {invoiceSubtotals
                    .map((subtotal) => subtotal.subTotalVatAmount)
                    .reduce((sum, amount) => sum + amount, 0)
                    .toFixed(2)}
                </td>
              </tr>
              <tr
                className={classNames(
                  'border-gray-200',
                  'border-t',
                  'hover:bg-gray-50',
                  'transition-colors',
                  'duration-200',
                  'ease-in-out'
                )}
              >
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3'>
                  <span className='sr-only'>Line Number</span>
                </td>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 text-right'>
                  Grand Total:
                </td>
                <td className='whitespace-nowrap py-4 text-sm font-medium text-gray-900 sm:pl-3'>
                  {billingCycles[0]?.invoice.lines[0].currencySymbol}
                  {(
                    invoiceSubtotals
                      .map((subtotal) => subtotal.subTotalVatAmount)
                      .reduce((sum, amount) => sum + amount, 0) +
                    invoiceSubtotals
                      .map((subtotal) => subtotal.subTotalAmount)
                      .reduce((sum, amount) => sum + amount, 0)
                  ).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </View>
  );
}
